import React, { Component } from 'react';

import { FeltTypeCheckBoxProps } from './felttypecheckbox.definition';

export class FeltTypeCheckBox extends Component<FeltTypeCheckBoxProps, {}> {
	constructor(props: any) {
		super(props);

		this.onChange = this.onChange.bind(this);
	}

	onChange(event:any) {
		this.props.onChange(event.target.value);
	}

	render() {
		let feltHtml = null;
		feltHtml = (
			<div className="begcheckbox-holder">
				<div key={ this.props.id }>
					<input type="checkbox" id={ this.props.id } name={ this.props.name } className={ this.props.className } onChange={ this.onChange } checked={ this.props.checked } />
					<label htmlFor={ this.props.name } className="radio-label">{ this.props.label }</label>
				</div>
			</div>
		);

		return feltHtml;
	}
}

export default FeltTypeCheckBox;