import React, { Component } from 'react';
import { Portal } from 'react-portal';

import { LoadingSpinnerProps } from './loadingSpinner.definition';

export class LoadingSpinner extends Component<LoadingSpinnerProps, {}> {
	constructor(props: LoadingSpinnerProps) {
		super(props);
	}

	renderPulse() {
		const { loadingText, loadingHeadline } = this.props;

		return (
			<div className="Loader-Pulse__Container">
				<p className="Loader-Pulse__Headline">
					{ loadingHeadline || 'Et øjeblik ...' }
				</p>
				<p className="Loader-Pulse__Text">
					{ loadingText || 'vi forbereder dit resultat' }
				</p>

				<div className="Loader-Pulse__Wrapper">
					<div className="Loader-Pulse">
						<div />
						<div />
						<div />
					</div>
				</div>
			</div>
		);
	}

	renderDefaultView() {
		const { isLoading, loadingText, flex, children } = this.props;

		return (
			<div className={ [isLoading && 'loading-block--loading local-loader', flex && 'flex'].join(' ') }>
				<div className="loading-block__loader-local">
					<div className="loading-block__cog cog">
						<svg
							version="1.1"
							id="cog__large"
							xmlns="http://www.w3.org/2000/svg"
							xmlnsXlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							viewBox="0 0 145 145"
							enableBackground="new 0 0 145 145"
							xmlSpace="preserve"
							className="cog__large">
							<path d="M138.6,66.3l-14.2-1.7c-0.6-3.9-1.6-7.6-3-11.1l11.4-8.6c0.9-0.5,1.2-1.7,0.7-2.6l-4.3-7.4c-0.5-0.9-1.7-1.2-2.6-0.7 l-13.2,5.6c-2.4-3-5.1-5.7-8.2-8.2l5.6-13.2c0.5-0.9,0.2-2.1-0.7-2.6l-7.4-4.3c-0.9-0.5-2.1-0.2-2.6,0.7l-8.6,11.4 c-3.5-1.4-7.3-2.4-11.1-3L78.7,6.4c0-1.1-0.9-1.9-1.9-1.9h-8.6c-1.1,0-1.9,0.9-1.9,1.9l-1.7,14.2c-3.9,0.6-7.6,1.6-11.1,3l-8.6-11.4 c-0.5-0.9-1.7-1.2-2.6-0.7l-7.4,4.3c-0.9,0.5-1.2,1.7-0.7,2.6l5.6,13.2c-3,2.4-5.7,5.1-8.2,8.2l-13.2-5.6c-0.9-0.5-2.1-0.2-2.6,0.7 l-4.3,7.4c-0.5,0.9-0.2,2.1,0.7,2.6l11.4,8.6c-1.4,3.5-2.4,7.3-3,11.1L6.4,66.3c-1.1,0-1.9,0.9-1.9,1.9v8.6c0,1.1,0.9,1.9,1.9,1.9 l14.2,1.7c0.6,3.9,1.6,7.6,3,11.1l-11.4,8.6c-0.9,0.5-1.2,1.7-0.7,2.6l4.3,7.4c0.5,0.9,1.7,1.2,2.6,0.7l13.2-5.6 c2.4,3,5.1,5.7,8.2,8.2l-5.6,13.2c-0.5,0.9-0.2,2.1,0.7,2.6l7.4,4.3c0.9,0.5,2.1,0.2,2.6-0.7l8.6-11.4c3.5,1.4,7.3,2.4,11.1,3 l1.7,14.2c0,1.1,0.9,1.9,1.9,1.9h8.6c1.1,0,1.9-0.9,1.9-1.9l1.7-14.2c3.9-0.6,7.6-1.6,11.1-3l8.6,11.4c0.5,0.9,1.7,1.2,2.6,0.7 l7.4-4.3c0.9-0.5,1.2-1.7,0.7-2.6l-5.6-13.2c3-2.4,5.7-5.1,8.2-8.2l13.2,5.6c0.9,0.5,2.1,0.2,2.6-0.7l4.3-7.4 c0.5-0.9,0.2-2.1-0.7-2.6l-11.4-8.6c1.4-3.5,2.4-7.3,3-11.1l14.2-1.7c1.1,0,1.9-0.9,1.9-1.9v-8.6C140.5,67.2,139.6,66.3,138.6,66.3z" />
						</svg>
						<svg
							version="1.1"
							id="cog__small"
							xmlns="http://www.w3.org/2000/svg"
							xmlnsXlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							viewBox="0 0 105 105"
							enableBackground="new 0 0 105 105"
							xmlSpace="preserve"
							className="cog__small">
							<path d="M100.6,46.3l-14-1.7c-0.9-3.8-2.4-7.4-4.4-10.6l8.7-11.1c0.7-0.7,0.7-1.9,0-2.7l-6.1-6.1c-0.7-0.7-1.9-0.7-2.7,0L71,22.8 c-3.2-2-6.8-3.5-10.6-4.4l-1.7-14c0-1.1-0.9-1.9-1.9-1.9h-8.6c-1.1,0-1.9,0.9-1.9,1.9l-1.7,14c-3.8,0.9-7.4,2.4-10.6,4.4l-11.1-8.7 c-0.7-0.7-1.9-0.7-2.7,0l-6.1,6.1c-0.7,0.7-0.7,1.9,0,2.7L22.8,34c-2,3.2-3.5,6.8-4.4,10.6l-14,1.7c-1.1,0-1.9,0.9-1.9,1.9v8.6 c0,1.1,0.9,1.9,1.9,1.9l14,1.7c0.9,3.8,2.4,7.4,4.4,10.6l-8.7,11.1c-0.7,0.7-0.7,1.9,0,2.7l6.1,6.1c0.7,0.7,1.9,0.7,2.7,0L34,82.2 c3.2,2,6.8,3.5,10.6,4.4l1.7,14c0,1.1,0.9,1.9,1.9,1.9h8.6c1.1,0,1.9-0.9,1.9-1.9l1.7-14c3.8-0.9,7.4-2.4,10.6-4.4l11.1,8.7 c0.7,0.7,1.9,0.7,2.7,0l6.1-6.1c0.7-0.7,0.7-1.9,0-2.7L82.2,71c2-3.2,3.5-6.8,4.4-10.6l14-1.7c1.1,0,1.9-0.9,1.9-1.9v-8.6 C102.5,47.2,101.6,46.3,100.6,46.3z" />
						</svg>
					</div>
					{ loadingText !== 'none' && <div className="loading-block__text">{ loadingText || 'Vent et øjeblik...' }</div> }

				</div>
				{ children }
			</div>
		);
	}

	renderSpinner() {
		const { type } = this.props;

		return type === 'pulse' ? this.renderPulse() : this.renderDefaultView();
	}

	render() {
		const { isLoading, isFullScreen = false } = this.props;

		if (!isLoading) {
			return null;
		}

		if (isFullScreen) {
			return (
				<Portal>
					<div className="Modal-Wrapper Modal-Wrapper__isActive">
						<div className="Modal" >
							<div className="small-12">
								{ this.renderSpinner() }
							</div>
						</div>
					</div>
				</Portal>
			);
		}

		return this.renderSpinner();
	}
}

export default LoadingSpinner;