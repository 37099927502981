/*
 * Project: forca
 * File Created: Wednesday, 20th October 2021 2:01:45 pm
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

import React from 'react';

import Icon from '@Components/icon';

import { TextBlockProps } from './textBlock.definition';

export const TextBlock = (props: TextBlockProps) => {
	const {
		title,
		content,
		useFullWidth,
		maxHeight,
		showAll,
		setShowAll,
		alignLeft = false,
		removeBottomMargin = false,
		elementMargin,
		id
	} = props;

	if (!content) {
		return null;
	}

	return (
		<div
			id={ id }
			className={ [
				'TextBlock',
				useFullWidth ? 'TextBlock--fullWidth' : '',
				(!showAll && maxHeight) ? 'TextBlock--hasMaxHeight' : '',
				alignLeft ? 'TextBlock--alignLeft' : '',
				removeBottomMargin ? 'TextBlock--removeBottomMargin' : '',
				elementMargin ? `mb-${elementMargin}` : ''
			].join(' ') }
			style={ { maxHeight: (!showAll && maxHeight) ? (Number(maxHeight) + 85) + 'px' : '' } }
		>
			{ title && (
				<h3 className="TextBlock-Title">{ title }</h3>
			) }
			<div className="TextBlock-Content" dangerouslySetInnerHTML={ { __html: content } } />
			{ !showAll && maxHeight && (
				<div className="readmore-button-buttonwrapper">
					<button className="readmore-button-button inline-button" onClick={ () => setShowAll(!showAll) }>
						<Icon icon="icon-arrow-right" />
					</button>
				</div>
			) }
		</div>
	);
};

export default TextBlock;