import React, { Component } from 'react';

import { ResultEntryProps } from './resultEntry.definition';

export class ResultEntry extends Component<ResultEntryProps, {}> {
	render() {
		return (
			<tr>
				<th className="col-50">{ this.props.text }</th>
				<th className="col-25 amount text-right">{ this.props.before }</th>
				<th className="col-25 amount text-right">{ this.props.after }</th>
			</tr>
		);
	}
}

export default ResultEntry;