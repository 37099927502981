/*
 * Project: forca
 * File Created: Monday, 30th August 2021 8:56:06 am
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

import React, { Component } from 'react';

import Image from '@Components/image';
import VideoQuoteBlockComponent from '@Components/videoQuoteBlock';

import { ImageDeckComponentProps } from './ImageDeck.definition';

class ImageDeckComponent extends Component<ImageDeckComponentProps> {
	render() {
		const {
			title,
			subtitle,
			content,
			button,
			buttonLink,
			buttonStyle,
			image,
			video,
			marketingCookiesDisabledText = '',
			marketingCookiesVideoInformation = '',
			isLeft = false,
			isVerticalCenter = false,
			isHidden = false,
			isFullWidth = false,
			isBoxed = false,
			imageSize = '40x47',
			isNarrow = false,
			isLhpDesign = false,
			elementMargin
		} = this.props;

		if (isHidden) {
			return null;
		}

		return (
			<div
				className={ [
					'ImageDeck',
					isLeft ? 'ImageDeck--Left' : 'ImageDeck--Right',
					isVerticalCenter ? 'ImageDeck--VerticalCenter' : '',
					isFullWidth ? 'ImageDeck--FullWidth' : '',
					isBoxed ? 'ImageDeck--Boxed' : '',
					isNarrow ? 'ImageDeck--Narrow' : '',
					isLhpDesign ? 'ImageDeck--LhpDesign' : '',
					elementMargin ? `mb-${elementMargin}` : ''
				].join(' ') }
			>
				<div className="ImageDeck-Box ImageDeck-ContentWrapper">
					{ title && (
						<h2
							className={ [
								'ImageDeck-Title'
							].join(' ') }
						>
							{ title }
						</h2>
					) }
					{ subtitle && <p className="ImageDeck-Subtitle">{ subtitle }</p> }
					<div className="ImageDeck-Content" dangerouslySetInnerHTML={ { __html: content } } />
					{ buttonLink && (
						<a
							className={ [
								'square-button',
								buttonStyle ? buttonStyle : 'button-type-3',
								'ImageDeck-Button'
							].join(' ') }
							href={ buttonLink }
						>
							{ button }
						</a>
					) }
				</div>
				<div className="ImageDeck-Box ImageDeck-ImageWrapper">
					{ video ? (
						<div
							className="base-band ImageDeck-Video"
							data-debuginfo={ video }
							data-cookieinformation={ marketingCookiesVideoInformation }
						>
							<VideoQuoteBlockComponent epiVideoUrl={ video } Headline="" MarketingCookiesDisabledText={ marketingCookiesDisabledText } Baggrundsfarve="#000" />
						</div>
					) : (
						<Image src={ image } ratio={ imageSize } title={ title } alt={ title } />
					) }
				</div>
			</div>
		);
	}
}

export default ImageDeckComponent;