/*
 * Project: forca
 * File Created: Friday, 15th October 2021 10:46:22 am
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

import React from 'react';

import Icon from '@Components/icon';
import { SVGIcon } from '../../../img/svg-icons';
import SectionHeading from '@Components/sectionHeading';
import Link from '@Components/link';

import { HotspotsProps, HotspotsBox } from './hotspots.definition';

export const Hotspots = (props: HotspotsProps) => {
	const { overskrift, overskriftLeftAlign = false, manchet, boxes = [], fullLink = false, elementMargin } = props;

	const renderIcon = (item: HotspotsBox) => {
		if (item.imageIcon) {
			return (
				<div className={ `${item.hideBooble ? 'Hotspots-Icon__NoBooble' : ''}` }>
					<img src={ item.imageIcon } alt={ item.title } />
				</div>
			);
		}

		if (!item.icon && !item.svgIcon) {
			return null;
		}

		return (
			<div className={ `Hotspots-${item.svgIcon ? 'SVG' : ''}Icon${ item.hideBooble ? ' Hotspots-Icon__NoBooble' : '' }` }>
				{ item.icon && !item.svgIcon && <Icon icon={ item.icon } /> }
				{ item.svgIcon && <SVGIcon name={ item.svgIcon } /> }
			</div>
		);
	};

	const renderTitle = (item: HotspotsBox) => {
		return (
			<h3 className="Hotspots-Title">{ item.title }</h3>
		);
	};

	const renderText = (item: HotspotsBox) => {
		if (!item.text) {
			return null;
		}

		return (
			<div className="Hotspots-Text" dangerouslySetInnerHTML={ { __html: item.text } } />
		);
	};

	const renderLink = (item: HotspotsBox, isFullLink: boolean) => {
		if (!item.linkHref) {
			return null;
		}

		if (isFullLink) {
			return (
				<span className="inline-button">
					<Icon icon="icon-arrow-right" />
					{ item.linkText }
				</span>
			);
		}

		return (
			<Link className={ item.isButton ? 'square-button button-type-1' : 'inline-button' } to={ item.linkHref }>
				{ !item.isButton && (
					<Icon icon="icon-arrow-right" />
				) }
				{ item.linkText }
			</Link>
		);
	};

	const renderCTA = (item: HotspotsBox) => {
		if (item.linkHref && !item.ctaText) {
			return null;
		}

		return (
			<p className="Hotspots-CTA">{ item.ctaText }</p>
		);
	};

	const renderContent = (item, isFullLink = false) => {
		return (
			<>
				{ renderIcon(item) }
				{ renderTitle(item) }
				{ renderText(item) }
				{ renderLink(item, isFullLink) }
				{ renderCTA(item) }
			</>
		);
	};

	const renderBox = (item: HotspotsBox, index: number) => (
		<div
			className={ [
				'Hotspots-Box',
				item.whiteBg ? 'Hotspots-Box__Whitebg' : '',
				item.isStatic ? 'Hotspots-Box--isStatic' : ''
			].join(' ') }
			key={ index }
		>
			{ renderContent(item) }
		</div>
	);

	const renderFullLink = (item: HotspotsBox, index: number) => (
		<a
			className={ [
				'Hotspots-Box',
				item.whiteBg ? 'Hotspots-Box__Whitebg' : '',
				item.isStatic ? 'Hotspots-Box--isStatic' : ''
			].join(' ') }
			href={ item.linkHref }
			key={ index }
		>
			{ renderContent(item, true) }
		</a>
	);

	if (boxes.length < 1) {
		return null;
	}

	return (
		<div
			className={ [
				'Hotspots',
				elementMargin ? `mb-${elementMargin}` : ''
			].join(' ') }
		>
			{ overskrift && (
				<SectionHeading
					headline={ overskrift }
					subheadline={ manchet }
					removeTopMargin
					lessBottomMargin
					alignLeft={ overskriftLeftAlign }
				/>
			) }
			<div className="Hotspots-Boxes">
				{ boxes.map((item, index) => fullLink ? renderFullLink(item, index) : renderBox(item, index)) }
			</div>
		</div>
	);
};

export default Hotspots;