/*
 * Project: forca
 * File Created: Monday, 20th September 2021 12:42:33 pm
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

import React from 'react';
import { Portal } from 'react-portal';

import Icon from '@Components/icon';

import { ModalProps } from './modal.definition';

export const Modal = (props: ModalProps) => {
	const { children, isActive = false, className, handleClose } = props;

	// on ESC click then close
	window.onkeydown = function( event ) {
		if ( event.keyCode == 27 ) {
			handleClose();
		}
	};

	// disable scroll on website if active
	if (isActive) {
		document.querySelector('body').classList.add('scrollDisabled');
	} else {
		document.querySelector('body').classList.remove('scrollDisabled');
	}

	// if click on "black" bg then close
	const handleBackgroundClose = (e) => {
		if (e.target.classList[0] === 'Modal') {
			handleClose();
		}
	};

	return (
		<Portal>
			<div
				className={ [
					'Modal-Wrapper',
					isActive ? 'Modal-Wrapper__isActive' : '',
					className ?? className
				].join(' ') }
			>
				<div className="Modal" onMouseDown={ handleBackgroundClose }>
					<div className="Modal-Content">
						<div className="Modal-Header">
							<button className="inline-button" onClick={ handleClose }>
								<Icon icon="icon-arrow-left" />
                                Tilbage
							</button>
						</div>
						{ children }
					</div>
				</div>
			</div>
		</Portal>
	);
};

export default Modal;