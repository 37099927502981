import React, { Component } from 'react';

import { SliderOverviewProps } from './sliderOverview.definition';

export class TekstOgMedieSliderOverview extends Component<SliderOverviewProps, {}> {
	constructor(props: any) {
		super(props);
	}


	render() {
		const self = this;

		const staticOffset = this.props.useCompactOffset ? 60 : 150;
		const overviewOffset = staticOffset - (29 * this.props.mainStepIndex);

		const overview = this.props.steps.map(function (content, index) {

			let stateClass = '';
			let descriptor = <span />;
			let subNodes = null;

			if (index === self.props.mainStepIndex) {
				stateClass = ' current';
				descriptor = <span className="descriptor-container"><span className="descriptor">{ content.title }</span></span>;

				if (content.subSteps.length > 1) {
					subNodes = content.subSteps.map(function (subNode, subIndex) {
						let stateClass = 'subnode-next';

						if (subIndex < self.props.subStepIndex) {
							stateClass = 'subnode-previous';
						}

						if (subIndex == self.props.subStepIndex) {
							stateClass = 'subnode-active';
						}

						return <li key={ subIndex } className={ stateClass } />;
					});
				}

				subNodes = <ul className="subnodes">{ subNodes }</ul>;
			}

			return <li key={ index } className="step-overview"><span className={ 'step-overview-node' + stateClass }>{ index + 1 }</span>{ descriptor }{ subNodes }</li>;
		});

		return (
			<div className="step-overview-absolute">
				<div className="step-overview-wrapper">
					<ul style={ { top: overviewOffset + 'px' } } className="step-overview-container">
						{ overview }
					</ul>
				</div>
			</div>
		);
	}
}

export default TekstOgMedieSliderOverview;