export interface PrognoseUdbetalingsforloebProps {
    valgtScenarieType: PrognoseType;
    overskrift: string;
    underOverskrift: string;
    dropdownLabel: string;
    folkepensionsalderLabel: string;
    folkepensionsalder: string;
    erAlderspensionist: boolean;
    scenarieTypeListe: PrognoseTypeViewModel[];
    faneGraf: string;
    overskriftGraf: string;
    underOverskriftGraf: string;
    forventetPrognoseTekstGraf: string;
    faneTabel: string;
    overskriftTabel: string;
    underOverskriftTabel: string;
    forventetPrognoseTekstTabel: string;
    fejlTekst: string;
    knapTekst: string;
    footerTekstTilLavPrognose: string;
    footerTekstTilMellemPrognose: string;
    footerTekstTilHoejPrognose: string;
    visMedlemskaberSamlet: boolean;
    visForloebAlderspension: boolean;
    afrundPrognosetal: boolean;
    blockId: string;
    visÍkkeFolkepensionsBoks: boolean;
    overskriftMedlemskabLav: string;
    overskriftMedlemskabMellem: string;
    overskriftMedlemskabHoej: string;
    tekstXAkse: string;
    tekstYAkse: string;
    tekstXAksePunkt0: string;
    tekstXAksePunktAndreAar: string;
    overskriftPopupAndreAar: string;
    overskriftPopupFoersteAar: string;
    tekstTabelAndreAar: string;
    tekstTabelFoersteAar: string;
    tekstTilRenSum: string;
    tekstVedUdloeb: string;
    visJson: boolean;
    baseInteraktionDialogId: string;
}

export enum PrognoseType {
    Lav,
    Mellem,
    Høj
}
export interface PrognoseTypeViewModel {
    vaerdi: PrognoseType;
    label: string;
    adfaerdHandling: string;
}

export interface PrognoseTilGraf {
    Label: string, Ydelse: number[], YdelseFormateret: string[], VisPaaGraf: boolean[], FarvePaaGraf: string
}
export interface PrognoserTilGraf {
    Aldre: number[], MedlemskabsNavn: string, PrognoserPrDaekning: PrognoseTilGraf[]
}

export interface UdbetalingsForloebState {
    resultFromApi: Record<string, PrognoseUdbetalingsforloeb[]>, nyValgtScenarieType: PrognoseType, visMineTal: boolean, width: number
}

export interface UdbetalingsforloebPrDaekning {
    AlderVistIGraf: number,
    MedlemsAlderIAar: number,
    MedlemsAlderIMdr: number,
    DatoStartFormateret: string,
    UdloebsdatoFormateret: string,
    PensionsAlderFormateret: string,
    Label: string,
    FarvePaaGraf: string,
    Ydelse: number,
    YdelseFormateret: string,
    VisPaaGraf: boolean,
    UdloebSkaevAlder: boolean,
    VisUdloebsTekst: boolean,
    VisUdloebsTekstMindreEndEtAar: boolean
}

export interface UdbetalingsforloebPrMedlemskab {
    LoebendePrognose: boolean,
    Prognoser: UdbetalingsforloebPrDaekning[]
}

export interface UdbetalingsforloebPrAlder {
    MedlemsAlderFormateret: string,
    Alder: any,
    DatoStartFormateret: string,
    UdloebsdatoFormateret: string,
    PensionsAlderFormateret: string,
    SumAfPrognoserFormateret: string,
    Prognoser: PrognoserPrAlder[]
}

export interface PrognoserPrAlder {
    Label: string,
    YdelseFormateret: string,
    LoebendeUdbetaling: boolean,
    VisUdloebsTekst: boolean
}

export interface PrognoseUdbetalingsforloeb {
    MedlemskabsNavn: string,
    PrognosetypeTekst: string,
    PrognosetypeKode: number,
    Kunde: string,
    VisFooterTekst: boolean,
    FooterTekst: string,
    PrognoserPrMedlemskab: UdbetalingsforloebPrMedlemskab[],
    PrognoserPrAlder: UdbetalingsforloebPrAlder[],
    Felter: PrognoseUdbetalingsforloebProps,
    Index: number
}
