export default class Utilities {
	static getWebsite() {
		const htmlElement = document.querySelector('html');
		return htmlElement.getAttribute('data-website') || 'pka';
	}

	/**
     * @param objectToTransform An array with data from the server
     */
	static transformArray(objectToTransform: any[], keyName = 'name', valueName = 'value'): any {
		return objectToTransform.reduce((acc, item) => {
			acc[item[keyName]] = item[valueName];
			return acc;
		}, {});
	}

	static replaceString(tekst: string, key: string, value: string): any {
		if (tekst != undefined) {
			return tekst.replace(key, value);
		}
	}

	static formatCurrency(amount: number) {
		return new Intl.NumberFormat('da-DK', { style: 'decimal', minimumFractionDigits: 0 }).format(amount);
	}

	static removeFormatCurrency(value) {
		return value.replace(/\./g, '').replace(/\,/g, '');
	}

	/**
     *Converts a number from Danish style to english
     *
     * removes thousands-seperator
     * Decimal-comma is converted to point
     *
     * @param decimal
     */
	static formatToDecimal(decimalToConvert: string): number{
		return Number(decimalToConvert.toString().replace(/\./g, '').replace(',', '.'));
	}

	/**
     *Finds an element in an array by looking after the property with value
     *
     * @param haystack - array to search throuh
     * @param needle - Value to find
     * @param propertyName - property to inspect.
     */
	static findArrayElementByProperty(haystack, needle, propertyName) {
		return haystack.find((element) => element[propertyName] === needle);
	}

	static filterElementByProperty(liste, propertyName) {
		return liste.map(element => element.name === propertyName);
	}

	static decodeHtmlEntities(text: string) {
		const textArea = document.createElement('textarea');
		textArea.innerHTML = text;
		return textArea.value;
	}

	static encodeHtmlEntities(text: string) {
		const textArea = document.createElement('textarea');
		textArea.innerText = text;
		return textArea.innerHTML;
	}

	static stripHtml(html: string) {
		let tekst = '';

		if (html != null) {
			tekst = html.replace('</td>', '|'); // Using pipe as seperator for now
			tekst = tekst.replace('<hr/>', '\n');
			tekst = tekst.replace('<br/>', '\n');
			tekst = tekst.replace('<br />', '\n');
			tekst = tekst.replace('</tr>', '\n');
			tekst = tekst.replace('</li>', '\n');
			tekst = tekst.replace('</p>', '\n');
			tekst = tekst.replace('|\n', '\n');
		}

		return tekst;
	}

	static stripTags(html: string) {
		let tekst = '';

		if (html != null) {
			tekst = Utilities.strReplaceAll('</td>', html, '');
			tekst = Utilities.strReplaceAll('<hr/>', tekst, '');
			tekst = Utilities.strReplaceAll('<br/>', tekst, '');
			tekst = Utilities.strReplaceAll('<br />', tekst, '');
			tekst = Utilities.strReplaceAll('</tr>', tekst, '');
			tekst = Utilities.strReplaceAll('</li>', tekst, '');
			tekst = Utilities.strReplaceAll('</p>', tekst, '');
			tekst = Utilities.strReplaceAll('<td>', tekst, '');
			tekst = Utilities.strReplaceAll('<tr>', tekst, '');
			tekst = Utilities.strReplaceAll('<li>', tekst, '');
			tekst = Utilities.strReplaceAll('<p>', tekst, '');
			tekst = Utilities.strReplaceAll('<div>', tekst, '');
			tekst = Utilities.strReplaceAll('</div>', tekst, '');
		}

		return tekst;
	}

	static replaceSpecialBogstaver(tekst: string) {
		let nyTekst = '';

		if (tekst != null) {
			nyTekst = Utilities.strReplaceAll('&aring;', tekst, 'å');
			nyTekst = Utilities.strReplaceAll('&aelig;', nyTekst, 'æ');
			nyTekst = Utilities.strReplaceAll('&oslash;', nyTekst, 'ø');
			nyTekst = Utilities.strReplaceAll('&Aring;', nyTekst, 'Å');
			nyTekst = Utilities.strReplaceAll('&AElig;', nyTekst, 'Æ');
			nyTekst = Utilities.strReplaceAll('&Oslash;', nyTekst, 'Ø');
		}

		return nyTekst;
	}

	static tablesLoops(loop = [], config) {
		const tableData = [];

		if (loop.length <= 0) {
			return tableData;
		}

		loop.map((item) => {
			config.map(cells => {
				const rows = [];

				cells.map(cell => {
					if (typeof cell === 'object') {
						rows.push(cell);
					} else {
						rows.push(item[cell]);
					}
				});

				tableData.push(rows);
			});
		});

		return tableData;
	}

	static checkForCookieInformationConsent(type) {
		const cookies = document.cookie;

		let match: any = cookies.match(new RegExp('(^| )CookieInformationConsent=([^;]+)'));

		// if cookieinformation isn't been loaded, then just show return true
		if (!match) {
			return true;
		}

		match = match[2];

		const decoded = decodeURIComponent(match);
		const decodedJSON = JSON.parse(decoded);

		if (match) {
			if (decodedJSON.consents_approved.find(item => item === type)) {
				return true;
			}
		}

		return false;
	}

	static dagsDato(type?: string, isLower?: boolean) {
		const locale = 'da';
		const today = new Date();
		const day = today.toLocaleDateString(locale, { weekday: 'long' });
		const month = today.toLocaleDateString(locale, { month: type === 'numbers' ? 'numeric' : 'long' });
		const year = today.toLocaleDateString(locale, { year: 'numeric' });
		const hour = today.getHours();
		const min = today.getMinutes();

		if (type === 'numbers') {
			return `${today.getDate()}/${month}/${year} ${hour}:${min}`;
		}

		const dayFirstCase = isLower ? day.charAt(0).toLowerCase() : day.charAt(0).toUpperCase();

		return `${dayFirstCase + day.slice(1)} d. ${today.getDate()}. ${month} ${year}\n\n`;
	}

	static isJqueryLoaded() {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (window.jQuery) {
			return true;
		}

		return false;
	}

	static convertNumberToWord(number: number) {
		const num = 'nul en to tre fire fem seks syv otte ni ti elleve tolv tretten fjorten femten seksten sytten atten nitten tyve'.split(' ');

		if (number <= 20) {
			return num[number];
		}

		return number.toString();
	}

	static isOverflown(element: any) {
		return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
	}

	static isScrolledToBottom(element: any) {
		return element.clientHeight + element.scrollY >= element.scrollHeight;
	}

	static isScrolledToTop(element: any) {
		return element.scrollTop === 0;
	}

	static isScrolledToRight(element: any) {
		return element.clientWidth + element.scrollLeft === element.scrollWidth;
	}

	static isScrolledToLeft(element: any) {
		return element.scrollLeft === 0;
	}

	static dokumentOverskrift(kunde, overskrift) {
		if (kunde == 'PKA') {
			return '<h1 style="color:#92004D; font-size: 18px;">' + overskrift + '</h1>';
		}

		if (kunde == 'PBU') {
			return '<h1 style="color:#000000; font-size: 18px;">' + overskrift + '</h1>';
		}

		if (kunde == 'LP') {
			return '<h1 style="color:#128A94; font-size: 18px;">' + overskrift + '</h1>';
		}
	}

	static dokumentBokse(kunde) {
		if (kunde == 'PKA') {
			return 'style = "background-color: #E9E4E1; padding: 15px;"';
		}

		if (kunde == 'PBU') {
			return 'style = "background-color: #F1EFEC; padding: 15px;"';
		}

		if (kunde == 'LP') {
			return 'style = "background-color: #F2F5F5; padding: 15px;"';
		}
	}

	static strReplaceAll(find, str, replace) {
		const escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
		const regex = new RegExp(escapedFind, 'g');
		return str.replace(regex, replace);
	}

	static firstLetterUppercase(value: string) {
		const firstLetter = value.charAt(0).toUpperCase();
		const remainingLetters = value.slice(1);
		return firstLetter + remainingLetters;
	}
}