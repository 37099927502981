import React from 'react';

import SectionHeading from '@Components/sectionHeading';
import TextBlock from '@Components/textBlock';

import { FaktaboksComponentProps } from './faktaboks.definition';

export const Faktaboks = (props: FaktaboksComponentProps) => {
	const {
		overskrift,
		subTitle,
		content,
		link,
		linkText,
		isCenter = false,
		elementMargin,
		isLhpLayout = false
	} = props;

	return (
		<div
			className={ [
				'Faktaboks',
				'Wrapper-Box',
				'Wrapper-Box__lessPadding',
				isCenter ? 'Faktaboks--isCenter' : '',
				isLhpLayout ? 'Faktaboks--isLhpLayout' : '',
				elementMargin ? `mb-${elementMargin}` : ''
			].join(' ') }
		>
			<div className="Faktaboks-Content">
				<SectionHeading
					headline={ overskrift }
					subheadline={ subTitle }
					alignLeft={ !isCenter }
					removeTopMargin
					lessBottomMargin
				/>

				<TextBlock
					content={ content }
					alignLeft={ !isCenter }
					removeBottomMargin
				/>

				{ link && (
					<div className="Faktaboks-ButtonWrapper">
						<a
							href={ link }
							className={ [
								'square-button',
								isLhpLayout ? 'button-type-5' : 'button-type-1'
							].join(' ') }
						>
							{ linkText }
						</a>
					</div>
				) }
			</div>
		</div>
	);
};

export default Faktaboks;