/*
 * Project: forca
 * File Created: Wednesday, 8th December 2021 2:01:28 pm
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

const { history } = window;
const { location } = window;

/**
  * Get query variable value
  */
export const getQueryParam = (variable: any) => {
	const query = location.search.substring(1);
	const vars = query.split('&');

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return vars.reduce((acc, item) => {
		const [k, v] = item.split('=');
		return k === variable ? v : acc;
	}, false);
};

/**
  * Convert url params to object with key value pairs
  */
export const convertQueryStringToKeyValuePairs = (queryString: string) => {
	const keyValuePairs = {};
	const params = queryString.substring(1).split('&');

	params.forEach((param: string) => {
		const pair = param.split('=');
		const [keyPair, valuePair = []] = pair;

		if (keyPair.length > 0 && valuePair.length > 0) {

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			keyValuePairs[keyPair] = decodeURIComponent(valuePair);
		}
	});

	return keyValuePairs;
};

/**
  * Update existing key value pairs and return result
  */
export const updateKeyValuePairs = (keyValuePairs: object, currentKey: string, currentValue: string) => {
	const updatedKeyValuePairs = {};

	Object.entries(keyValuePairs).forEach((pair) => {
		const [key, value] = pair;

		if (currentKey === key) {
			updatedKeyValuePairs[key] = currentValue;
		} else {
			updatedKeyValuePairs[key] = value;
		}
	});

	return updatedKeyValuePairs;
};

/**
  * Convert object with key value pairs to url query string
  */
export const convertKeyValuesToQueryString = (keyValuePairs: object) => Object.entries(keyValuePairs)
	.map((pair) => {
		const [key, value] = pair;
		const keyExists = key !== '';
		const valueExists = typeof value === 'object' ? value.length : value !== '';

		if (valueExists && keyExists) {
			return `${key}=${value}`;
		}

		return null;
	})
	.filter((x) => !!x)
	.join('&');

export const generateQuery = (keyValueObject: object) => Object.entries(keyValueObject)
	.reduce((acc, pair) => {
		const [key, value] = pair;

		const keyAndValueExist = !!key && !!value;

		if (acc === '' && keyAndValueExist) {
			return `?${key}=${value}`;
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (getQueryParam(key) !== false) {
			const keyValuePairs = convertQueryStringToKeyValuePairs(acc);
			const updatedKeyValuePairs = updateKeyValuePairs(keyValuePairs, key, value);
			const updatedQuery = convertKeyValuesToQueryString(updatedKeyValuePairs);

			return updatedQuery.length ? `?${updatedQuery}` : '';
		}

		if (keyAndValueExist) {
			return `${acc}&${key}=${value}`;
		}

		return acc;
	}, location.search);

/**
  * Set add key value pairs to url
  */
export const setQueryParams = (keyValueObject: object) => {
	const { state } = history;
	const query = generateQuery(keyValueObject);

	history.replaceState(state, '', query);
};

/**
  * Update query params without adding to history
  */
export const updateQueryParam = (name: string, value: any) => {
	setQueryParams({ [name]: value });
};

/**
  * Remove query param without adding to history
  */
export const removeQueryParam = (name: string) => {
	const { search, pathname } = location;
	const { state } = history;

	const params = new URLSearchParams(search);
	params.delete(name);
	history.replaceState(state, '', decodeURIComponent(`${ pathname }?${ params }`));
};

/**
  * Remove all queries except default sort options from url
  */
export const clearQueriesFromUrl = () => {
	history.replaceState({}, '', location.pathname);
};