import React, { Component } from 'react';

import { ChangeReportComparisonProps, ChangeReportComparisonState, ChangeReportComparisonVersion, ChangeReportComparisonEntry } from './changeReportComparison.definition';

export class ChangeReportComparison extends Component<ChangeReportComparisonProps, ChangeReportComparisonState> {
	constructor(props: ChangeReportComparisonProps) {
		super(props);
		this.state = {
			versions: null,
			version1: '-1',
			version2: '-1',
			comparisonEntries: [],
			loading: false
		};
		this.onVersion1Selected = this.onVersion1Selected.bind(this);
		this.onVersion2Selected = this.onVersion2Selected.bind(this);
	}

	async componentDidUpdate(prevProps: ChangeReportComparisonProps) {
		if (!this.props.contentId) {
			return;
		}

		if (this.props.contentId === prevProps.contentId) {
			return;
		}

		this.setState({
			loading: true
		});

		const response = await fetch('/api/changereportapi/getcontentversions', {
			method: 'POST',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(this.props.contentId)
		});
		const jsonData = await response.json();

		const newVersions: ChangeReportComparisonVersion[] = [];

		for (let i = 0; i < jsonData.length; i++) {
			const jsonElement = jsonData[i];

			const version = new ChangeReportComparisonVersion();
			version.contentLink = jsonElement.ContentLink;
			version.savedBy = jsonElement.SavedBy;
			version.saveDate = new Date(jsonElement.Saved).toISOString().substr(0, 19).replace('T', ' ');

			newVersions.push(version);
		}

		this.setState({
			versions: newVersions,
			version1: '-1',
			version2: '-1',
			comparisonEntries: [],
			loading: false
		});
	}

	async doComparison(version1: string, version2: string) {
		if (version1 === '-1' || version2 === '-1') {
			return;
		}

		this.setState({
			loading: true
		});

		const version1Promise = this.fetchVersionData(version1);
		const version2Promise = this.fetchVersionData(version2);

		const version1Data = await version1Promise;
		const version2Data = await version2Promise;

		const newComparison: ChangeReportComparisonEntry[] = [];
		for (const prop in version1Data) {
			if (version1Data.hasOwnProperty(prop)) {
				if (version1Data[prop] !== version2Data[prop]) {
					const entry = new ChangeReportComparisonEntry();
					entry.propertyName = prop;
					entry.version1Value = version1Data[prop];
					entry.version2Value = version2Data[prop];

					newComparison.push(entry);
				}
			}
		}

		this.setState({
			comparisonEntries: newComparison,
			loading: false
		});

	}

	async fetchVersionData(contentLink: string) {
		const response = await fetch('/api/changereportapi/getcontentversion', {
			method: 'POST',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ contentId: this.props.contentId, contentLink: contentLink })
		});

		const jsonData = await response.json();

		return jsonData;
	}

	onVersion1Selected(event: any) {
		this.setState({
			version1: event.target.value
		});

		this.doComparison(event.target.value, this.state.version2);
	}

	onVersion2Selected(event: any) {
		this.setState({
			version2: event.target.value
		});

		this.doComparison(this.state.version1, event.target.value);
	}

	render() {

		let versions = null;

		if (this.state.versions) {
			versions = this.state.versions.map((version) => <option key={ version.contentLink } value={ version.contentLink }>{ version.saveDate } - { version.savedBy }</option>);
			versions.unshift(<option value="-1" key="-1">Vælg version</option>);
		}

		let comparison = null;

		if (this.state.comparisonEntries) {
			comparison = this.state.comparisonEntries.map((entry) =>
				(
					<div className="top-margin-1rem" key={ entry.propertyName }>
						<strong>{ entry.propertyName }</strong>
						<hr />
						<div className="row">
							<div className="small-6 columns">
								{ entry.version1Value }
							</div>
							<div className="small-6 columns">
								{ entry.version2Value }
							</div>
						</div>
					</div>
				)
			);
		}

		if (this.state.loading) {
			return <div className="ajax-loader loader" />;
		} else if (this.state.versions) {
			return (
				<div className="comparison-view">
					<strong>{ this.props.contentName }</strong>
					<div className="row">
						<div className="small-6 columns">
							<select value={ this.state.version1 } onChange={ this.onVersion1Selected }>
								{ versions }
							</select>
						</div>
						<div className="small-6 columns">
							<select value={ this.state.version2 } onChange={ this.onVersion2Selected }>
								{ versions }
							</select>
						</div>
					</div>
					<div className="row">
						{ comparison }
					</div>
				</div>
			);
		} else {
			return <div className="row"><div className="small-6 columns">Vælg et element til sammenligning</div></div>;
		}
	}
}

export default ChangeReportComparison;