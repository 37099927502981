import React, { Component } from 'react';

import Icon from '@Components/icon';

import { ComparisonTableProps, ComparisonElementProps } from './comparisonTable.definition';
import ComparisonTableRows from './components/comparisonTableRows';
import ComparisonTableHeader from './components/comparisonTableHeader';

import { ComparisonTableState } from './comparisonTable.definition';

export class ComparisonTable extends Component<ComparisonTableProps, ComparisonTableState> {
	constructor(props: ComparisonTableProps) {
		super(props);

		const { leftSideChoices, rightSideChoices } = props;

		this.state = {
			leftSideIndex: leftSideChoices.length == 1 ? 0 : -1,
			rightSideIndex: rightSideChoices.length == 1 ? 0 : -1
		};

		this.leftSideIndexChange = this.leftSideIndexChange.bind(this);
		this.rightSideIndexChange = this.rightSideIndexChange.bind(this);
		this.clearSideIndexes = this.clearSideIndexes.bind(this);
	}

	leftSideIndexChange(newIndex: number) {
		this.setState({
			leftSideIndex: newIndex
		});
	}

	rightSideIndexChange(newIndex: number) {
		this.setState({
			rightSideIndex: newIndex
		});
	}

	clearSideIndexes() {
		const { leftSideChoices, rightSideChoices } = this.props;

		this.setState({
			leftSideIndex: leftSideChoices.length == 1 ? 0 : -1,
			rightSideIndex: rightSideChoices.length == 1 ? 0 : -1
		});
	}

	renderTableHeader(leftSide, rightSide) {
		return (
			<div className="ComparisonTable-Row ComparisonTable-RowHeader">
				<div className="ComparisonTable-Columns">&nbsp;</div>
				<div className="ComparisonTable-Columns">
					<span dangerouslySetInnerHTML={ { __html: leftSide?.headerText } } />
				</div>
				<div className="ComparisonTable-Columns">
					<span dangerouslySetInnerHTML={ { __html: rightSide?.headerText } } />
				</div>
			</div>
		);
	}

	render() {
		const {
			leftSideIndex,
			rightSideIndex
		} = this.state;
		const {
			introText,
			choiceText,
			leftSideChoices,
			rightSideChoices,
			choiceTagManagerId
		} = this.props;

		const leftSide: ComparisonElementProps = leftSideIndex > -1 ? leftSideChoices[leftSideIndex] : null;
		const rightSide: ComparisonElementProps = rightSideIndex > -1 ? rightSideChoices[rightSideIndex] : null;

		return (
			<div className="ComparisonTable-Wrapper">
				<div dangerouslySetInnerHTML={ { __html: introText } } />

				<ComparisonTableHeader
					defaultChoiceText={ choiceText }
					leftSideChoices={ leftSideChoices }
					leftSideIndex={ leftSideIndex }
					rightSideChoices={ rightSideChoices }
					rightSideIndex={ rightSideIndex }
					onLeftSideIndexChanged={ this.leftSideIndexChange }
					onRightSideIndexChanged={ this.rightSideIndexChange }
					choiceTagManagerId={ choiceTagManagerId }
				/>

				{ leftSide && rightSide && (
					<div className="ComparisonTable">
						<div className="ComparisonTable-ResetWrapper">
							<button className="inline-button ComparisonTable-Reset" onClick={ this.clearSideIndexes }>
                                Luk
								<Icon icon="icon-close-icon" />
							</button>
						</div>
						{ this.renderTableHeader(leftSide, rightSide) }
						<ComparisonTableRows leftSide={ leftSide } rightSide={ rightSide } />
						<div dangerouslySetInnerHTML={ { __html: this.props.footerText } } />
					</div>
				) }
			</div>
		);
	}
}

export default ComparisonTable;