import React, { Component } from 'react';
import FeltTypeComboBox from './components/felttypecombobox';
import FeltTypeSlider from './components/felttypeslider';
import FeltTypeCheckBox from './components/felttypecheckbox';
import ResultEntry from './components/resultEntry';

import { TilpasForsikringerFormularProps, TilpasForsikringerFormularState } from './tilpasforsikringerformular.definition';

export class TilpasForsikringerFormular extends Component<TilpasForsikringerFormularProps, TilpasForsikringerFormularState> {
	constructor(props: TilpasForsikringerFormularProps) {
		super(props);
		this.state = {
			amountValues: null,
			apiResultInProgress: false,
			contentId: null,
			loading: false,
			resultFromApi: [],
			justerbareFelter: this.props.justerbareFelter,
			showVidere: false
		};
	}

	async udførBeregning() {
		const data: any = {};
		for (let i = 0; i < this.state.justerbareFelter.length; i++) {
			const currentFelt = this.state.justerbareFelter[i];

			if (currentFelt.erTilvalgt) {
				data[currentFelt.dataNavn] = currentFelt.valgtVærdi;
			}
		}

		const response = await fetch('/api/tilpasforsikringerapi/BeregnForsikringer', {
			method: 'POST',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		});
		const jsonData = await response.json();
		this.setState({ resultFromApi: jsonData, apiResultInProgress: false });
	}

	async næsteStep() {
		const response = await fetch('/tilpasforsikringerformularblock/godkend', {
			method: 'GET',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json'
			}
			//body: JSON.stringify(data)
		});
		const jsonData = await response.json();
		this.setState({ resultFromApi: jsonData, apiResultInProgress: false });
	}

	beregn = () => {
		this.udførBeregning();
		this.setState({ showVidere: true });
	};

	videre = () => {
		this.næsteStep();
	};

	onSelectChanged(i: number, opdateretVærdi: string) {
		const currentFelt = { ...this.state.justerbareFelter[i] };
		currentFelt.valgtVærdi = opdateretVærdi;

		const justerbareFelterKopi = [...this.state.justerbareFelter];
		justerbareFelterKopi[i] = currentFelt;
		this.setState({
			justerbareFelter: justerbareFelterKopi
		});

		this.setState({ showVidere: false });
	}

	onCheckBoxChecked(i: number) {
		const currentFelt = { ...this.state.justerbareFelter[i] };
		currentFelt.erTilvalgt = !currentFelt.erTilvalgt;
		currentFelt.valgtVærdi = currentFelt.muligeVærdier[0];

		const justerbareFelterKopi = [...this.state.justerbareFelter];
		justerbareFelterKopi[i] = currentFelt;
		this.setState({
			justerbareFelter: justerbareFelterKopi
		});

		this.setState({ showVidere: false });
	}

	render() {
		if (this.state.loading) {
			return <div className="ajax-loader loader" />;
		} else {
			const htmlItems = [];
			const nuvaerendeForsikringer = [];
			let entries: any = [];

			for (let i = 0; i < this.state.justerbareFelter.length; i++) {
				const currentElement = this.state.justerbareFelter[i];

				const forsikringer = <p>{ currentElement.nuværendeVærdi }</p>;

				const felt =
					currentElement.kanFravælges ? <span><p><FeltTypeCheckBox className="" id="" label="" name="" onChange={ this.onCheckBoxChecked.bind(this, i) } checked={ false } /> tilvælg { currentElement.navn.toLowerCase() }?</p></span> : '';

				const feltElement =
					currentElement.erTilvalgt ?
						currentElement.feltType == 0 ?
							<FeltTypeComboBox muligeVaerdier={ currentElement.muligeVærdier } onValueChanged={ this.onSelectChanged.bind(this, i) } valgtVaerdi={ currentElement.valgtVærdi } /> :
							<FeltTypeSlider minVærdi={ currentElement.minVærdi } maxVærdi={ currentElement.maxVærdi } onValueChanged={ this.onSelectChanged.bind(this, i) } valgtVærdi={ currentElement.valgtVærdi } /> : '';

				const feltHtml = (
					<div className="small-12 columns"><strong>{ currentElement.navn }</strong>
						{ felt }
						{ feltElement }
					</div>
				);

				nuvaerendeForsikringer.push(forsikringer);
				htmlItems.push(feltHtml);
			}

			if (this.state.resultFromApi) {
				entries = (
					<div className="table-swipe">
						<table>
							<tbody>
								{ this.state.resultFromApi.map((entry => <ResultEntry key={ entry.text } text={ entry.text } before={ entry.before } after={ entry.after } />)) }
							</tbody>
						</table>
					</div>
				);
			}

			const showVidere = this.state.showVidere ? 'block' : 'none';

			return (
				<div className="comparison-view">
					<div className="row">
						<h3>Nuværende forsikringer:</h3>
						{ nuvaerendeForsikringer }
					</div>
					<div className="row">
						{ htmlItems }
					</div>
					<div className="row">
						<br />
						<button onClick={ this.beregn } disabled={ showVidere != 'none' }>{ this.props.beregnKnapLabel } </button>
					</div>
					<div className="columns large-12 focus-box__text">
						<div className="row">
							{ entries }
						</div>
					</div>
					<div className="row" style={ { display: showVidere } }>
						<br />
						<button onClick={ this.videre }>{ this.props.videreKnapLabel }</button>
					</div>
				</div>
			);
		}
	}
}

export default TilpasForsikringerFormular;