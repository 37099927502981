import React from 'react';

import Faktaboks from './faktaboks.component';

export const FaktaboksContainer = (props: any) => {
	const containerProps = () => {
		const { currentBlock = null, link } = props;

		if (!currentBlock) {
			return props;
		}

		const {
			overskrift,
			subTitle,
			content,
			linkText,
			isCenter,
			elementMargin,
			isLhpLayout
		} = currentBlock;

		return {
			overskrift,
			subTitle,
			content,
			linkText,
			link,
			isCenter,
			elementMargin,
			isLhpLayout
		};
	};

	return (
		<Faktaboks
			{ ...containerProps() }
		/>
	);
};

export default FaktaboksContainer;