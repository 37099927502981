/*
 * Project: forca
 * File Created: Friday, 29th October 2021 11:04:41 am
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({
	isPDF: false
});

export const setIsPDF = (value: boolean) => {
	setGlobalState('isPDF', () => ( value ));
};

export { useGlobalState };