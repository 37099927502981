/*
 * Project: forca
 * File Created: Monday, 29th November 2021 10:25:17 am
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

export const iconList = [
	{ icon: 'icon-step-circle' },
	{ icon: 'icon-search-icon' },
	{ icon: 'icon-open-lock' },
	{ icon: 'icon-right-arrow' },
	{ icon: 'icon-checkbox-selected' },
	{ icon: 'icon-close-icon' },
	{ icon: 'icon-radio-button-selected-pka' },
	{ icon: 'icon-radio-button-pka' },
	{ icon: 'icon-contact-bubble' },
	{ icon: 'icon-phone' },
	{ icon: 'icon-mobile-tabs-icons-open' },
	{ icon: 'icon-mobile-tabs-icons-close' },
	{ icon: 'icon-Pil-op' },
	{ icon: 'icon-Pil-ned' },
	{ icon: 'icon-link-arrow' },
	{ icon: 'icon-chatbubble' },
	{ icon: 'icon-wheel' },
	{ icon: 'icon-closed-lock' },
	{ icon: 'icon-facebook' },
	{ icon: 'icon-twitter' },
	{ icon: 'icon-info' },
	{ icon: 'icon-quoteend' },
	{ icon: 'icon-quotestart' },
	{ icon: 'icon-minus' },
	{ icon: 'icon-plus' },
	{ icon: 'icon-link-arrow-new' },
	{ icon: 'icon-chatbubble-text' },
	{ icon: 'icon-calendar' },
	{ icon: 'icon-printer' },
	{ icon: 'icon-refresh' },
	{ icon: 'icon-arrow-left' },
	{ icon: 'icon-arrow-right' },
	{ icon: 'icon-profile' },
	{ icon: 'icon-envelope' },
	{ icon: 'icon-PKA-advisor' },
	{ icon: 'icon-forca-arrow' },
	{ icon: 'icon-expand-black' },
	{ icon: 'icon-expanded-black' },
	{ icon: 'icon-alderssum-ikon' },
	{ icon: 'icon-livsvarrig-ikon' },
	{ icon: 'icon-ratepension-ikon' },
	{ icon: 'icon-linkedin-logo' },
	{ icon: 'icon-youtube-logo' },
	{ icon: 'icon-folkepension' },
	{ icon: 'icon-permissions' },
	{ icon: 'icon-shield' },
	{ icon: 'icon-Sygdom-Copy', paths: 4 },
	{ icon: 'icon-Nemid_Ikon' },
	{ icon: 'icon-pbu-forsikringer' },
	{ icon: 'icon-pbu-forsikringer' },
	{ icon: 'icon-pbu-indbetalinger' },
	{ icon: 'icon-pencil' },
	{ icon: 'icon-checkmark-rounded' },
	{ icon: 'icon-Tabel' },
	{ icon: 'icon-graf' },
	{ icon: 'icon-moneybag' },
	{ icon: 'icon-pka-aegteskab' },
	{ icon: 'icon-pka-aktivt_ejerskab' },
	{ icon: 'icon-pka-alderspension' },
	{ icon: 'icon-pka-app' },
	{ icon: 'icon-pka-arbejdstagerrettigheder' },
	{ icon: 'icon-pka-barometer' },
	{ icon: 'icon-pka-beregner' },
	{ icon: 'icon-pka-biomasseanlaeg' },
	{ icon: 'icon-pka-blade' },
	{ icon: 'icon-pka-boern' },
	{ icon: 'icon-pka-boreplatform' },
	{ icon: 'icon-pka-boreplatform_forbud' },
	{ icon: 'icon-pka-chat' },
	{ icon: 'icon-pka-checkmaerke' },
	{ icon: 'icon-pka-computer' },
	{ icon: 'icon-pka-delegerede' },
	{ icon: 'icon-pka-delpension' },
	{ icon: 'icon-pka-demokrati' },
	{ icon: 'icon-pka-doed' },
	{ icon: 'icon-pka-facebook' },
	{ icon: 'icon-pka-foraeldre_barn' },
	{ icon: 'icon-pka-fritid' },
	{ icon: 'icon-pka-globus_vaekst' },
	{ icon: 'icon-pka-graf_linje' },
	{ icon: 'icon-pka-graf_soejle' },
	{ icon: 'icon-pka-groen_klode' },
	{ icon: 'icon-pka-groenne_ejendomme' },
	{ icon: 'icon-pka-groenne_obligationer' },
	{ icon: 'icon-pka-hjem' },
	{ icon: 'icon-pka-hjerte_cardio' },
	{ icon: 'icon-pka-haandtryk' },
	{ icon: 'icon-pka-i-arbejde' },
	{ icon: 'icon-pka-impact-fonde' },
	{ icon: 'icon-pka-instagram' },
	{ icon: 'icon-pka-jordklode' },
	{ icon: 'icon-pka-kvinde' },
	{ icon: 'icon-pka-laegetaske' },
	{ icon: 'icon-pka-linkedIn' },
	{ icon: 'icon-pka-mand' },
	{ icon: 'icon-pka-medlem' },
	{ icon: 'icon-pka-mikrofinansiering' },
	{ icon: 'icon-pka-minedrift' },
	{ icon: 'icon-pka-minedrift_forbud' },
	{ icon: 'icon-pka-mobiltelefon' },
	{ icon: 'icon-pka-moenter_sedler' },
	{ icon: 'icon-pka-mor_barn' },
	{ icon: 'icon-pka-papirark' },
	{ icon: 'icon-pka-par' },
	{ icon: 'icon-pka-paraply' },
	{ icon: 'icon-pka-pengebunke' },
	{ icon: 'icon-pka-pengesaek' },
	{ icon: 'icon-pka-pengesedler_moenter' },
	{ icon: 'icon-pka-pengestabler' },
	{ icon: 'icon-pka-person_spoergsmaal' },
	{ icon: 'icon-pka-post_brev' },
	{ icon: 'icon-pka-post_email' },
	{ icon: 'icon-pka-rapport' },
	{ icon: 'icon-pka-recycle' },
	{ icon: 'icon-pka-risiko' },
	{ icon: 'icon-pka-raadgivning' },
	{ icon: 'icon-pka-sanktioner' },
	{ icon: 'icon-pka-skilsmisse' },
	{ icon: 'icon-pka-skov' },
	{ icon: 'icon-pka-skydeskive' },
	{ icon: 'icon-pka-smoergsmaalstegn' },
	{ icon: 'icon-pka-sol' },
	{ icon: 'icon-pka-sol_sky' },
	{ icon: 'icon-pka-solcellepark' },
	{ icon: 'icon-pka-sparegris' },
	{ icon: 'icon-pka-stemme-imod' },
	{ icon: 'icon-pka-stemmeseddel' },
	{ icon: 'icon-pka-tale_sammen' },
	{ icon: 'icon-pka-thumbs_up' },
	{ icon: 'icon-pka-tobaksindustri' },
	{ icon: 'icon-pka-tobaksindustri_forbud' },
	{ icon: 'icon-pka-twitter' },
	{ icon: 'icon-pka-uddannelse' },
	{ icon: 'icon-pka-vandboring' },
	{ icon: 'icon-pka-vindmoelle_land' },
	{ icon: 'icon-pka-vindmoelle_vand' },
	{ icon: 'icon-pka-vaabenindustri' },
	{ icon: 'icon-pka-vaabenindustri_forbud' },
	{ icon: 'icon-pka-loobende_10_aar' },
	{ icon: 'icon-obs' },
	{ icon: 'icon-check' },
	{ icon: 'icon-info-ny' },
	{ icon: 'icon-Forstoerrelsesglas' },
	{ icon: 'icon-Konvolut' },
	{ icon: 'icon-Profil' },
	{ icon: 'icon-lp-aegteskab', paths: 4 },
	{ icon: 'icon-lp-afkast-rente', paths: 6 },
	{ icon: 'icon-lp-alderspension', paths: 2 },
	{ icon: 'icon-lp-balance', paths: 13 },
	{ icon: 'icon-lp-barnevogn', paths: 2 },
	{ icon: 'icon-lp-beregner', paths: 5 },
	{ icon: 'icon-lp-biomasseanlaeg', paths: 7 },
	{ icon: 'icon-lp-blade', paths: 3 },
	{ icon: 'icon-lp-boern', paths: 4 },
	{ icon: 'icon-lp-chat', paths: 4 },
	{ icon: 'icon-lp-delpension', paths: 6 },
	{ icon: 'icon-lp-deportrente', paths: 8 },
	{ icon: 'icon-lp-doed', paths: 4 },
	{ icon: 'icon-lp-efterln', paths: 6 },
	{ icon: 'icon-lp-e-post', paths: 4 },
	{ icon: 'icon-lp-familie', paths: 5 },
	{ icon: 'icon-lp-flueben', paths: 7 },
	{ icon: 'icon-lp-fritid', paths: 12 },
	{ icon: 'icon-lp-genbrug', paths: 6 },
	{ icon: 'icon-lp-graf1', paths: 3 },
	{ icon: 'icon-lp-graf2', paths: 4 },
	{ icon: 'icon-lp-groenne-aktier', paths: 3 },
	{ icon: 'icon-lp-groenne-ejendomme', paths: 4 },
	{ icon: 'icon-lp-haengelaas', paths: 2 },
	{ icon: 'icon-lp-hjerte', paths: 3 },
	{ icon: 'icon-lp-husstand', paths: 4 },
	{ icon: 'icon-lp-i-arbejde', paths: 8 },
	{ icon: 'icon-lp-jordklode', paths: 4 },
	{ icon: 'icon-lp-kvinde', paths: 3 },
	{ icon: 'icon-lp-livsvsarig', paths: 7 },
	{ icon: 'icon-lp-lyspaere', paths: 3 },
	{ icon: 'icon-lp-mand', paths: 4 },
	{ icon: 'icon-lp-mobil', paths: 4 },
	{ icon: 'icon-lp-mobil-haand', paths: 3 },
	{ icon: 'icon-lp-moent', paths: 4 },
	{ icon: 'icon-lp-opsparing', paths: 6 },
	{ icon: 'icon-lp-overblik', paths: 8 },
	{ icon: 'icon-lp-papirbunke', paths: 5 },
	{ icon: 'icon-lp-papirflyver', paths: 3 },
	{ icon: 'icon-lp-par', paths: 4 },
	{ icon: 'icon-lp-pengesaek', paths: 2 },
	{ icon: 'icon-lp-pengesaek-haand', paths: 2 },
	{ icon: 'icon-lp-pengeseddel', paths: 3 },
	{ icon: 'icon-lp-pengestabel', paths: 12 },
	{ icon: 'icon-lp-pensionist-kvinde', paths: 3 },
	{ icon: 'icon-lp-pensionist-mand', paths: 3 },
	{ icon: 'icon-lp-pensionstjek', paths: 3 },
	{ icon: 'icon-lp-person', paths: 3 },
	{ icon: 'icon-lp-person-spoergsmaal', paths: 4 },
	{ icon: 'icon-lp-person-syg', paths: 5 },
	{ icon: 'icon-lp-planlaegning', paths: 2 },
	{ icon: 'icon-lp-post', paths: 4 },
	{ icon: 'icon-lp-raadgiver', paths: 8 },
	{ icon: 'icon-lp-rapport', paths: 3 },
	{ icon: 'icon-lp-rate', paths: 10 },
	{ icon: 'icon-lp-risiko', paths: 6 },
	{ icon: 'icon-lp-samlepension', paths: 7 },
	{ icon: 'icon-lp-seddelstabel', paths: 6 },
	{ icon: 'icon-lp-skilsmisse', paths: 4 },
	{ icon: 'icon-lp-skydeskive', paths: 7 },
	{ icon: 'icon-lp-sol', paths: 18 },
	{ icon: 'icon-lp-solcelle', paths: 13 },
	{ icon: 'icon-lp-speaker', paths: 2 },
	{ icon: 'icon-lp-spoergsmaalstegn', paths: 8 },
	{ icon: 'icon-lp-stemmeseddel', paths: 5 },
	{ icon: 'icon-lp-sygdom', paths: 12 },
	{ icon: 'icon-lp-tablet-haand', paths: 4 },
	{ icon: 'icon-lp-tablet', paths: 4 },
	{ icon: 'icon-lp-thumbsUp', paths: 4 },
	{ icon: 'icon-lp-to-der-taler-sammen', paths: 9 },
	{ icon: 'icon-lp-trae', paths: 2 },
	{ icon: 'icon-lp-udraabstegn', paths: 6 },
	{ icon: 'icon-lp-vindmoelle', paths: 3 },
	{ icon: 'icon-lp-website', paths: 6 },
	{ icon: 'icon-laas_lukket' },
	{ icon: 'icon-laas_aaben' },
	{ icon: 'icon-stabil' },
	{ icon: 'icon-right-arrow-line' },
	{ icon: 'icon-plus-line' },
	{ icon: 'icon-minus-line' },
	{ icon: 'icon-udbetaling' },
	{ icon: 'icon-gennemsnitsrente' },
	{ icon: 'icon-filter' },
	{ icon: 'icon-pbu-ikon-fordele-ingen' },
	{ icon: 'icon-pbu-ikon-fordele-tilknyttet' },
	{ icon: 'icon-pbu-ikon-investeringer-ansvarlig' },
	{ icon: 'icon-pbu-ikon-investeringer-ikke-afgoerende' },
	{ icon: 'icon-pbu-ikon-investeringer-indflydelse' },
	{ icon: 'icon-pbu-ikon-investeringer-specialister' },
	{ icon: 'icon-pbu-ikon-omkostninger-lave' },
	{ icon: 'icon-pbu-ikon-omkostninger-varierer' },
	{ icon: 'icon-pbu-ikon-overskud-investorer' },
	{ icon: 'icon-pbu-ikon-overskud-medlemmer' }
];