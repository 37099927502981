export interface ChangeReportComparisonProps {
    contentId: string,
    contentName: string
}

export class ChangeReportComparisonEntry {
	propertyName: string;

	version1Value: string;

	version2Value: string;
}

export interface ChangeReportComparisonState {
    versions: ChangeReportComparisonVersion[],
    comparisonEntries: ChangeReportComparisonEntry[],
    version1: string,
    version2: string,
    loading: boolean
}

export class ChangeReportComparisonVersion {
	savedBy: string;

	saveDate: string;

	contentLink: string;
}