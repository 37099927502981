import React, { useState } from 'react';
import { AnbefalingProps, RecommendationListProps } from './RecommendationList.definition';
import Hotspots from '@Components/hotspots';
import Modal from '@Components/modal';
import ImageDeck from '@Components/ImageDeck';
import parse from 'html-react-parser';
import Faktaboks from '@Components/faktaboks';

const RecommendationList = (props: RecommendationListProps) => {
	const { overskrift, knapTekst, knapLink, dato, foraeldetTekst, foraeldetOverskrift, overskriftFaktaBoks, imageDeck, faktaBoks, visFaktaBoks } = props;
	const [openModal, setOpenModal] = useState(0);

	function openModalFunction(index) {
		setOpenModal(index);
	}

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window.openModalFunction = openModalFunction;

	const renderReminder = () => {
		if (visFaktaBoks) {
			return (
				<>
					<h3 className="RecommendationList-Title">{ overskriftFaktaBoks }</h3>
					<h5 className="RecommendationList-Subtitle">{ dato }</h5>
					<Faktaboks { ...faktaBoks } />
				</>
			);
		}

		if (!imageDeck) {
			return null;
		}

		return (
			<ImageDeck { ...imageDeck } />
		);
	};

	const renderModals = () => {
		const { anbefalinger } = props;

		return (
			<>
				{ anbefalinger.map(anbefaling => renderModal(anbefaling, anbefaling.id)) }
			</>
		);
	};

	const renderModal = (data: AnbefalingProps, i: number) => {
		const { icon, modalOverskrift, modalText, text, modalFooter, buttonLink1, buttonText1, buttonLink2, buttonText2 } = data;
		return (
			<Modal
				key={ i }
				className="RecommendationList-Modal"
				isActive={ openModal === i }
				handleClose={ () => setOpenModal(0) }
			>
				<div className="RecommendationList-Modal__Icon">
					<img src={ icon } alt={ modalOverskrift } />
				</div>
				<div className="RecommendationList-Modal__Content">
					<span>{ modalOverskrift }</span>
					<h4>{ modalText }</h4>
					<p>
						{ parse(text) }
					</p>
					{ buttonText1 && (
						<div>
							<a className="square-button button-type-3" href={ buttonLink1 }>
								{ buttonText1 }
							</a>
						</div>
					) }
					{ buttonText2 && (
						<div>
							<a className="square-button button-type-3" href={ buttonLink2 }>
								{ buttonText2 }
							</a>
						</div>
					) }

				</div>
				<div className="RecommendationList-Modal__Terms">
					<span>
						{ parse(modalFooter) }
					</span>
				</div>
			</Modal>
		);
	};

	const renderInfomationBox = () => {
		return (
			<div className="RecommendationList-InformationBox">
				<div>
					<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle opacity="0.5" cx="16" cy="16" r="15.5" stroke="#222222" strokeOpacity="0.6" />
						<path d="M14.6827 22V13.072H17.3287V22H14.6827ZM15.9967 11.758C15.5527 11.758 15.1927 11.632 14.9167 11.38C14.6407 11.128 14.5027 10.792 14.5027 10.372C14.5027 9.952 14.6407 9.616 14.9167 9.364C15.1927 9.112 15.5527 8.986 15.9967 8.986C16.4407 8.986 16.8007 9.112 17.0767 9.364C17.3527 9.616 17.4907 9.952 17.4907 10.372C17.4907 10.792 17.3527 11.128 17.0767 11.38C16.8007 11.632 16.4407 11.758 15.9967 11.758Z" fill="#CE0060" />
					</svg>
				</div>
				<div>
					<div className="event-box__title">
						{ foraeldetOverskrift }
					</div>
					<div className="event-box__content">
						{ parse(foraeldetTekst) }
					</div>
				</div>
			</div>
		);
	};

	const { anbefalinger } = props;

	const newAnbefalingArray = [];
	anbefalinger.forEach(anbefaling => {
		newAnbefalingArray.push({
			link: `javascript:openModalFunction(${anbefaling.id})`,
			currentBlock: {
				imageIcon: anbefaling.icon,
				title: anbefaling.title,
				text: anbefaling.modalOverskrift,
				isButton: false,
				linkText: '',
				ctaText: '',
				hideIconBubble: true,
				useWhiteBackground: true,
				isStatic: true
			}
		});
	});

	if (newAnbefalingArray.length <= 0) {
		return renderReminder();
	}

	return (
		<div className="RecommendationList">
			<h3 className="RecommendationList-Title">{ overskrift }</h3>
			<h5 className="RecommendationList-Subtitle">{ dato }</h5>
			<div className="RecommendationList-List">
				<Hotspots
					currentBlock={ {
						fullLink: true
					} }
					hotSpotList={ newAnbefalingArray }
				/>
			</div>
			{ foraeldetTekst && renderInfomationBox() }
			<div
				className={ [
					'RecommendationList-ButtonWrapper',
					foraeldetTekst ? 'RecommendationList-ButtonWrapper--Left' : ''
				].join(' ') }>
				<a className="square-button button-type-3" href={ knapLink } >
					{ knapTekst }
				</a>
			</div>
			{ renderModals() }
		</div>
	);
};

export default RecommendationList;