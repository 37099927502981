/*
 * Project: forca
 * File Created: Tuesday, 26th October 2021 3:14:56 pm
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

import React, { useState } from 'react';

import { useGlobalState } from '@Store/default.store';

import { ImageProps } from './image.definition';

export const Image = (props: ImageProps) => {
	const [isPDF] = useGlobalState('isPDF');
	const [isNotFound, setIsNotFound] = useState(false);
	const {
		alt,
		src = '',
		style,
		title,
		ratio,
		className,
		posHoz,
		posVer,
		elementMargin
	} = props;

	const stylePos = {
		objectPosition: `${posHoz || 'center' } ${posVer || 'center' }`
	};

	const renderPlaceholder = (text: string) => (
		<div className="Image-Placeholder" data-error-text={ text } />
	);

	const renderImage = () => (
		<img
			className="Image-Image"
			src={ src }
			alt={ alt }
			style={ { ...style, ...stylePos } }
			title={ title }
			loading={ !isPDF ? 'lazy' : 'eager' }
			onError={ () => setIsNotFound(true) }
		/>
	);

	return (
		<div
			className={ [
				'Image',
				ratio ? 'Image_ratio_' + ratio : 'Image_ratio_square',
				className && className,
				elementMargin ? `mb-${elementMargin}` : ''
			].join(' ') }
		>
			{ !isNotFound && src && renderImage() }
			{ !isNotFound && !src && renderPlaceholder('Billedet er ikke angivet') }
			{ isNotFound && renderPlaceholder('Billedet blev ikke fundet') }
		</div>
	);
};

export default Image;
