import React, { Component } from 'react';

import { ChangeReportEntryProps } from './changeReportEntry.definition';

export class ChangeReportEntry extends Component<ChangeReportEntryProps, {}> {
	render() {

		const link = '/EPiServer/CMS/#context=epi.cms.contentdata:///' + this.props.contentLink;

		return (
			<div className="row version-entry">
				<div className="small-7 columns">
					{ this.props.changeDate }
				</div>
				<div className="small-4 columns">
					{ this.props.changedBy }
				</div>
				<div className="small-1 columns">
					<a href={ link } target="EPiServerMainUI">
						<span className="icon-link-arrow right" />
					</a>
				</div>
			</div>
		);
	}
}

export default ChangeReportEntry;