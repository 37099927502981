/* eslint-disable @typescript-eslint/ban-ts-comment */
/*
 * Project: forca
 * File Created: Friday, 22nd October 2021 2:08:50 pm
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

import React, { Component } from 'react';

import SectionHeading from './sectionHeading.component';

class SectionHeadingContainer extends Component<any> {
	containerProps() {
		const { currentBlock = null } = this.props;

		if (!currentBlock) {
			return this.props;
		}

		const {
			currentBlock: {
				headline,
				subHeadline: subheadline,
				text,
				alignLeft,
				noTopMargin: removeTopMargin,
				headlineSize,
				lessBottomMargin,
				headlineColor,
				elementMargin
			}
		} = this.props;

		return {
			headline,
			subheadline,
			text,
			alignLeft,
			removeTopMargin,
			headlineSize,
			lessBottomMargin,
			headlineColor,
			elementMargin
		};
	}

	render() {
		return (
			<>
				{ /* @ts-ignore */ }
				<SectionHeading
					{ ...this.containerProps() }
				/>
			</>
		);
	}
}

export default SectionHeadingContainer;