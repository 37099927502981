/*
 * Project: forca
 * File Created: Monday, 29th November 2021 10:17:40 am
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

import React from 'react';

import { iconList } from './icon.config';

import { IconProps } from './icon.definition';

export const Icon = (props: IconProps) => {
	const { icon, style } = props;

	const fullClassName = `icon ${icon}`;

	const paths = iconList.find((item) => item.icon === icon)?.paths;

	// if any paths then add them to array
	const array = [];
	for (let i = 0; i < paths; i++) {
		array.push({ name: icon + i, index: i + 1 });
	}

	return (
		<i className={ fullClassName } style={ style }>
			{ array.map((path: any) => <span key={ path.name } className={ `path${path.index}` } /> ) }
		</i>
	);
};

export default Icon;