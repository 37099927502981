/*
 * Project: forca
 * File Created: Monday, 8th November 2021 1:02:50 pm
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

import React from 'react';

import Image from './image.component';

export const ImageContainer = (props: any) => {
	const containerProps = () => {
		const { currentBlock = null } = props;

		if (!currentBlock) {
			return props;
		}

		const {
			image: src,
			currentBlock: {
				altText: alt,
				title,
				imageRatio: ratio,
				imagePositionHorizontal: posHoz,
				imagePositionVertical: posVer,
				elementMargin
			}
		} = props;

		return {
			src,
			alt,
			title,
			ratio,
			posHoz,
			posVer,
			elementMargin
		};
	};

	return (
		<Image
			{ ...containerProps() }
		/>
	);
};

export default ImageContainer;