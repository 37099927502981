import { ChangeReportEntryProps } from '../changeReportEntry/changeReportEntry.definition';

export class ChangeReportGroupingProps {
	contentName: string;

	contentId: string;

	entries: ChangeReportEntryProps[];

	onOpenComparison: (ev: any) => void;
}