import React, { useState, useEffect } from 'react';

import { VideoQuoteBlockProps } from './videoQuoteBlock.definition';

import utils from '@Utils/utilities';
import VideoSkyfish from '@Components/videoSkyfish';

export const VideoQuoteBlockComponent = (props: VideoQuoteBlockProps) => {
	const [isVisible, setVisibility] = useState(false);
	const [acceptedCookies, setAcceptedCookies] = useState(false);

	useEffect(() => {
		window.addEventListener('CookieInformationConsentGiven', function() {
			if (window.CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
				setAcceptedCookies(true);
			} else {
				setAcceptedCookies(false);
			}
		}, false);

		return () => {
			window.removeEventListener('CookieInformationConsentGiven', () => null);
		};
	}, []);

	const { Headline, MarketingCookiesDisabledText, Baggrundsfarve, epiVideoUrl, id } = props;
	let emptyId = null;

	let videoUrl;

	if (epiVideoUrl.includes('watch?v=')) {
		if (!id && epiVideoUrl) {
			emptyId = epiVideoUrl?.split('watch?v=')[1];
		}

		videoUrl = `https://www.youtube.com/embed/${emptyId}`;
	} else if (epiVideoUrl.includes('skyfish')) {
		emptyId = '';
		videoUrl = '';
	} else {
		if (!id && epiVideoUrl) {
			emptyId = epiVideoUrl?.split('embed/')[1].split('?')[0];
		}

		videoUrl = epiVideoUrl;
	}

	const element = document.querySelector(`.base-band[data-debuginfo="${videoUrl}"]`);
	const quote = element?.getAttribute('data-quote');
	const MarketingCookiesVideoInformation = element?.getAttribute('data-cookieinformation');

	const handleRenewCookieInformation = () => {
		window.CookieConsent.renew();
	};

	useEffect((): any => {
		setAcceptedCookies(utils.checkForCookieInformationConsent('cookie_cat_marketing'));
	}, [isVisible]);

	const renderSkyfishVideo = () => {
		return (
			<div id={ props.matomoId }>
				<VideoSkyfish
					url={ epiVideoUrl }
					id={ `skyfish-VideoQuoteBlockComponent-${Headline}` }
				/>
			</div>
		);
	};

	return (
		<div className="row">
			<div className="columns video-with-text-block">
				<div className={ `row collapse gradient gradient--1 ${ Baggrundsfarve }` }>
					<div className={ `columns medium-12 ${ (Headline || quote) ? 'large-8' : 'large-12' } rich-media-box__media` }>
						{ epiVideoUrl.includes('skyfish') ? renderSkyfishVideo() : (
							<div className="flex-video" id={ props.matomoId }>
								{ !isVisible && (
									<div className="flex-video-img-placeholder" onClick={ () => setVisibility(true) }>
										<img src={ `https://img.youtube.com/vi/${ id || emptyId }/maxresdefault.jpg` } />
										<div className="absolute bottom" dangerouslySetInnerHTML={ { __html: MarketingCookiesVideoInformation } } />
									</div>
								) }
								{ isVisible && (
									<>
										<div className="flex-video-img-placeholder" data-category-consent="cookie_cat_marketing">
											<img src={ `https://img.youtube.com/vi/${ id || emptyId }/maxresdefault.jpg` } />
											<div className="absolute">
												<a onClick={ handleRenewCookieInformation } dangerouslySetInnerHTML={ { __html: MarketingCookiesDisabledText } } />
											</div>
										</div>

										{ acceptedCookies && (
											<iframe width="1280" height="720" src={ acceptedCookies ? videoUrl : '' } data-category-consent="cookie_cat_marketing" data-consent-src={ videoUrl } />
										) }

										{ !acceptedCookies && (
											<div className="absolute bottom" dangerouslySetInnerHTML={ { __html: MarketingCookiesVideoInformation } } />
										) }
									</>
								) }
							</div>
						) }
					</div>
					{ (Headline || quote) && (
						<div className="columns medium-12 large-4 end rich-media-box__sidebar" data-equalizer-watch>
							<div className="text-holder">
								<p className="header">
									{ Headline }
								</p>
								<p className="quote">
									<q>
										{ quote }
									</q>
								</p>
							</div>
						</div>
					) }
				</div>
			</div>
		</div>
	);
};

export default VideoQuoteBlockComponent;