import React, { Component, ReactNode } from 'react';

import { SliderStepProps } from './sliderSteps.definition';

export class TekstOgMedieSliderSteps extends Component<SliderStepProps, {}> {
	subSteps: any;

	title: ReactNode;

	constructor(props: any) {
		super(props);
	}

	render() {
		const self = this;

		const elements = this.props.steps.map(function (content, index) {
			const subElements = content.subSteps.map(function (subContent, subIndex) {

				let stateClass = 'step-current';
				let mediaStateClass = 'step-current';
				let isCurrentItem = true;

				if (index < self.props.mainStepIndex || (index == self.props.mainStepIndex && subIndex < self.props.subStepIndex)) {
					stateClass = 'step-before';
					mediaStateClass = 'step-before';
					isCurrentItem = false;
				} else if (index > self.props.mainStepIndex || subIndex > self.props.subStepIndex) {
					stateClass = 'step-after';
					mediaStateClass = 'step-after';
					isCurrentItem = false;
				} else {
					if (self.props.showMobileVideo) {
						stateClass = 'step-before';
					} else {
						mediaStateClass = 'step-after';
					}
				}


				return (
					<div key={ index + '-' + subIndex }>
						<div className={ stateClass + ' step-animate step-base' }>
							<h2>{ subContent.title }</h2>
							<p dangerouslySetInnerHTML={ { __html: subContent.expandedText } } />
						</div>
						{ self.props.isCompactView && (
							<div className={ mediaStateClass + ' step-animate step-base step-media' }>
								{ isCurrentItem && self.props.mediaElement }
							</div>
						) }


					</div>
				);
			});

			return <div key={ index }>{ subElements }</div>;
		});

		return (
			<div className="step-container">
				{ elements }
			</div>
		);
	}
}

export default TekstOgMedieSliderSteps;