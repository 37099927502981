/*
 * Project: forca
 * File Created: Monday, 20th September 2021 2:41:30 pm
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

import React from 'react';

import { SectionHeadingProps } from './sectionHeading.definition';

export const SectionHeading = (props: SectionHeadingProps) => {
	const {
		headline,
		subheadline,
		text,
		alignLeft = false,
		headlineSize = 'default',
		removeTopMargin = false,
		lessBottomMargin = false,
		headlineColor = null,
		elementMargin
	} = props;

	const type = `SectionHeading__${headlineSize}`;
	const NoTopMargin = removeTopMargin ? 'SectionHeading__noTopMargin' : '';
	const LessBottomMargin = lessBottomMargin ? 'SectionHeading__lessBottomMargin' : '';

	return (
		<div
			className={ [
				'SectionHeading',
				alignLeft ? 'SectionHeading__isLeft' : '',
				type,
				NoTopMargin,
				LessBottomMargin,
				elementMargin ? `mb-${elementMargin}` : ''
			].join(' ') }
		>
			<h1 className="SectionHeading-Headline" style={ { color: headlineColor } }>{ headline }</h1>
			{ subheadline && (
				<h2 className="SectionHeading-SubHeadline" style={ { color: headlineColor } }>{ subheadline }</h2>
			) }
			{ text && (
				<p className="SectionHeading-Text" style={ { color: headlineColor } } dangerouslySetInnerHTML={ { __html: text } } />
			) }
		</div>
	);
};

export default SectionHeading;