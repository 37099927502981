import React, { Component } from 'react';

import { ChangeReportGroupingProps } from './components/changeReportGrouping/changeReportGrouping.definition';
import ChangeReportGrouping from './components/changeReportGrouping';
import { ChangeReportEntryProps } from './components/changeReportEntry/changeReportEntry.definition';
import ChangeReportComparison from './components/changeReportComparison';

import { ChangeReportState } from './changeReport.definition';

export class ChangeReport extends Component<{}, ChangeReportState> {
	constructor(props: any) {
		super(props);
		this.state = {
			reportData: [],
			currentComparisonContentId: null,
			currentComparisonContentName: null,
			fromDate: null,
			toDate: null
		};
		this.onOpenComparison = this.onOpenComparison.bind(this);
		this.onFromDateChanged = this.onFromDateChanged.bind(this);
		this.onToDateChanged = this.onToDateChanged.bind(this);
	}

	async fetchChanges(fromDate: Date, toDate: Date) {
		if (!fromDate || !toDate) {
			return;
		}

		const response = await fetch('/api/changereportapi/getchanges', {
			method: 'POST',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ fromDate: fromDate, toDate: toDate })
		});
		const jsonData = await response.json();

		const newState: ChangeReportGroupingProps[] = [];

		for (let i = 0; i < jsonData.length; i++) {
			const currentData = jsonData[i];

			const existingIndex = newState.findIndex(element => element.contentId == currentData.ContentGuid);

			const newEntry = new ChangeReportEntryProps();
			newEntry.changeDate = new Date(currentData.Created).toISOString().substr(0, 19).replace('T', ' ');
			newEntry.changedBy = currentData.ChangedBy;
			newEntry.contentLink = currentData.ContentLink;
			newEntry.actionType = currentData.ActionType;

			if (existingIndex > -1) {
				newState[existingIndex].entries.push(newEntry);
			} else {
				const newGrouping = new ChangeReportGroupingProps();
				newGrouping.contentId = currentData.ContentGuid;
				newGrouping.contentName = currentData.Name;
				const newEntries: ChangeReportEntryProps[] = [];
				newGrouping.entries = newEntries;
				newGrouping.entries.push(newEntry);

				newState.push(newGrouping);
			}
		}


		this.setState({
			reportData: newState
		});
	}

	onOpenComparison(data: ChangeReportGroupingProps) {
		this.setState({
			currentComparisonContentId: data.contentId,
			currentComparisonContentName: data.contentName
		});
	}

	onFromDateChanged(event:any) {
		const newDate = event.target.value;
		this.setState({
			fromDate: newDate
		});

		this.fetchChanges(newDate, this.state.toDate);
	}

	onToDateChanged(event: any) {
		const newDate = event.target.value;
		this.setState({
			toDate: newDate
		});

		this.fetchChanges(this.state.fromDate, newDate);
	}

	render() {
		const changes = this.state.reportData.map((change) => <ChangeReportGrouping onOpenComparison={ this.onOpenComparison } contentId={ change.contentId } contentName={ change.contentName } entries={ change.entries } key={ change.contentId } />);

		return (
			<div className="content-change-report">
				<h1>Rapport over content-ændringer</h1>
				<div className="row no-margin">
					<div className="small-6 columns">
						<span>Fra</span>
						<input onChange={ this.onFromDateChanged } type="date" />
					</div>
					<div className="small-6 columns">
						<span>Til</span>
						<input onChange={ this.onToDateChanged } type="date" />
					</div>
				</div>

				<div className="row no-margin">
					<div className="small-4 columns version-list">
						{ changes }
					</div>
					<div className="small-8 columns" data-stick-container>
						<ChangeReportComparison contentName={ this.state.currentComparisonContentName } contentId={ this.state.currentComparisonContentId } />
					</div>
				</div>
			</div>
		);
	}
}

export default ChangeReport;