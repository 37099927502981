/*
 * Project: forca
 * File Created: Monday, 8th November 2021 1:02:50 pm
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

import React, { useState } from 'react';

import TextBlock from './textBlock.component';

export const TextBlockContainer = (props: any) => {
	const [showAll, setShowAll] = useState(false);

	const containerFunctions = {
		setShowAll: setShowAll.bind(this)
	};

	const containerProps = () => {
		const { currentBlock = null } = props;

		if (!currentBlock) {
			return props;
		}

		const {
			currentBlock: {
				title,
				content,
				useFullWidth,
				maxHeight,
				elementMargin
			}
		} = props;

		return {
			title,
			content,
			useFullWidth,
			maxHeight,
			showAll,
			setShowAll,
			elementMargin
		};
	};

	return (
		<TextBlock
			{ ...containerProps() }
			{ ...containerFunctions }
		/>
	);
};

export default TextBlockContainer;