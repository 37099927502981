/* eslint-disable @typescript-eslint/ban-ts-comment */
/*
 * Project: forca
 * File Created: Monday, 9th August 2021 4:20:20 pm
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

import React, { Component } from 'react';

import ImageDeckComponent from './ImageDeck.component';

class ImageDeckContainer extends Component<any> {
	containerProps() {
		const { currentBlock = null } = this.props;

		if (!currentBlock) {
			return this.props;
		}

		const {
			currentBlock: {
				title,
				subTitle: subtitle,
				content,
				button,
				alignLeft: isLeft,
				isVerticalCenter,
				useFullWidth: isFullWidth,
				isBoxed,
				imageRatio: imageSize,
				isNarrow,
				isLhpDesign,
				elementMargin,
				video
			},
			buttonLink,
			buttonStyle,
			image
		} = this.props;

		return {
			title,
			subtitle,
			content,
			button,
			buttonLink: buttonLink?.originalString ? buttonLink.originalString : buttonLink,
			buttonStyle,
			image,
			isLeft,
			isVerticalCenter,
			isFullWidth,
			isBoxed,
			imageSize,
			isNarrow,
			isLhpDesign,
			video,
			elementMargin
		};
	}

	render() {
		return (
			<>
				{ /* @ts-ignore */ }
				<ImageDeckComponent
					{ ...this.containerProps() }
				/>
			</>
		);
	}
}

export default ImageDeckContainer;