import React, { Component } from 'react';

import { FeltTypeSliderProps } from './felttypeslider.definition';

export class FeltTypeSlider extends Component<FeltTypeSliderProps, {}> {
	constructor(props: any) {
		super(props);

		this.onValueChanged = this.onValueChanged.bind(this);
	}

	onValueChanged(event: any) {
		this.props.onValueChanged(event.target.value);
	}

	render() {
		const feltHtml = (
			<div>
				<div>
					<input type="range" min={ this.props.minVærdi } max={ this.props.maxVærdi } value={ this.props.valgtVærdi } onChange={ this.onValueChanged } />
					<span> { this.props.valgtVærdi } %</span>
				</div>
			</div>
		);

		return feltHtml;
	}
}

export default FeltTypeSlider;