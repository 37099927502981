import React, { Component } from 'react';

import Icon from '@Components/icon';

import { TabContainerProps, TabContainerState } from './tabContainer.definition';

export class TabContainer extends Component<TabContainerProps, TabContainerState> {
	constructor(props: any) {
		super(props);
		this.state = {
			selectedIndex: props.selected,
			icon: props.icon
		};
	}

	setTab(index: number) {
		this.setState({
			selectedIndex: index
		});

		if (this.props.tabUpdate) {
			this.props.tabUpdate(index);
		}
	}

	render() {
		const self = this;
		const selectedTab = (!this.props.isMobile) ? this.props.children[this.state.selectedIndex] : this.props.children;

		return (
			<div className="row">
				<div className="small-12">
					{ !this.props.isMobile && (
						<>
							<ul className="simpletabs">
								{ this.props.children.map((child, index) => {
									const selected = index == self.state.selectedIndex ? 'simpletabs__item--active' : '';

									return (
										<li key={ index } onClick={ () => this.setTab(index) } className={ 'simpletabs__item ' + selected }>
											<a className="simpletabs__link">
												<div className="icon" />
												<span>{ child.props.tabHeader }</span>
											</a>
											<i className={ child.props.icon } />
										</li>
									);
								}) }
							</ul>
							<div className="columns small-12 tab-container tabsPile">
								{ this.state.selectedIndex > 0 && <a className="buttonMoveToNextTab" onClick={ () => this.setTab(this.state.selectedIndex - 1) } type="button"> <Icon icon="icon-arrow-left" /></a> }
								{ this.state.selectedIndex < this.props.children.length - 1 &&
									<a className="buttonMoveToPrevTab" onClick={ () => this.setTab(this.state.selectedIndex + 1) } type="button"> <Icon icon="icon-arrow-right" /></a> }
							</div>
						</>
					) }
					{ selectedTab }
				</div>
			</div>
		);
	}
}

export default TabContainer;