import React, { Component } from 'react';

import { ComparisonHeaderProps, ComparisonElementProps } from '../../comparisonTable.definition';

class ComparisonTableHeader extends Component<ComparisonHeaderProps, {}> {
	constructor(props: ComparisonHeaderProps) {
		super(props);
		this.leftSideIndexChange = this.leftSideIndexChange.bind(this);
		this.rightSideIndexChange = this.rightSideIndexChange.bind(this);
	}

	leftSideIndexChange(event: React.FormEvent<HTMLSelectElement>) {
		this.props.onLeftSideIndexChanged(parseInt(event.currentTarget.value, 10));
	}

	rightSideIndexChange(event: any) {
		this.props.onRightSideIndexChanged(parseInt(event.currentTarget.value, 10));
	}

	getOptions(choices: ComparisonElementProps[]): JSX.Element[] {
		let index = 0;
		const options = choices.map(function (option) {
			return <option key={ index } value={ index++ }>{ option.choiceText }</option>;
		});

		options.unshift(<option key={ -1 } value={ -1 }>{ this.props.defaultChoiceText }</option>);

		return options;
	}

	render() {
		let leftSideSelector: JSX.Element, rightSideSelector: JSX.Element;

		if (this.props.leftSideChoices.length > 1) {
			const options = this.getOptions(this.props.leftSideChoices);
			leftSideSelector = <select onChange={ this.leftSideIndexChange } value={ this.props.leftSideIndex }>{ options }</select>;
		}

		if (this.props.rightSideChoices.length > 1) {
			const options = this.getOptions(this.props.rightSideChoices);
			rightSideSelector = <select id={ this.props.choiceTagManagerId } onChange={ this.rightSideIndexChange } value={ this.props.rightSideIndex }>{ options }</select>;
		}

		return (leftSideSelector || rightSideSelector) ? (
			<div className="row">
				<div className="columns small-12 medium-5">
					<p><strong>Vælg bank</strong></p>
					{ rightSideSelector }
				</div>
				<div className="columns small-2">
                    &nbsp;
				</div>
				<div className="columns small-12 medium-5">
					{ leftSideSelector }
				</div>
			</div>
		) : null;
	}
}

export default ComparisonTableHeader;