import React, { Component } from 'react';

import { ComparisonRowProps, ComparisonAttributeProps } from '../../comparisonTable.definition';

class ComparisonTableRows extends Component<ComparisonRowProps, {}> {
	constructor(props: ComparisonRowProps) {
		super(props);
	}

	render() {
		const { leftSide, rightSide } = this.props;

		if (!leftSide || !rightSide) {
			return null;
		}

		const attributes: ComparisonAttributeProps[] = leftSide?.attributes ?? rightSide?.attributes;

		return attributes.map(function (attribute) {
			const { key } = attribute;

			const leftSideValue = leftSide?.attributes.find(x => x.key == key)?.value;
			const rightSideValue = rightSide?.attributes.find(x => x.key == key)?.value;

			return (
				<div className="ComparisonTable-Row" key={ key }>
					<div className="ComparisonTable-Columns">
						{ key }
					</div>
					<div className="ComparisonTable-Columns">
						<div dangerouslySetInnerHTML={ { __html: leftSideValue } } />
					</div>
					<div className="ComparisonTable-Columns">
						<div dangerouslySetInnerHTML={ { __html: rightSideValue } } />
					</div>
				</div>
			);
		});
	}
}

export default ComparisonTableRows;