/* eslint-disable react/no-unsafe */

import React, { Component } from 'react';
import * as MedlemsAdfaerdService from '@Utils/medlemsAdfaerdService';
import LoadingSpinner from '@Components/loadingSpinner';
import TabContainer from '@Components/tabContainer';
import Tab from '@Components/tabContainer/components/tab';
import UdbetalingsForloebGraf from './components/UdbetalingsForloebGraf';
import UdbetalingsForloebTabel from './components/UdbetalingsForloebTabel';

import { PrognoseUdbetalingsforloebProps, PrognoseType, UdbetalingsForloebState, PrognoseUdbetalingsforloeb } from './UdbetalingsForloeb.definition';

type PrognoseKomponent = new (...args: any[]) => Component<PrognoseUdbetalingsforloeb >;

export class UdbetalingsForloeb extends Component<PrognoseUdbetalingsforloebProps, UdbetalingsForloebState> {
	constructor(props: PrognoseUdbetalingsforloebProps) {
		super(props);
		const results = {} as Record<string, PrognoseUdbetalingsforloeb[]>;
		for (let i = 0; i < this.props.scenarieTypeListe.length; i++) {
			results[this.props.scenarieTypeListe[i].vaerdi] = null;
		}
		this.state = {
			resultFromApi: results,
			nyValgtScenarieType: props.valgtScenarieType,
			visMineTal: false,
			width: window.innerWidth
		};
		this.onPrognoseChanged = this.onPrognoseChanged.bind(this);

	}

	UNSAFE_componentWillMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	async componentDidMount() {
		const succeeded = await this.udførBeregning(this.props.valgtScenarieType);

		if (succeeded) {
			this.udførBeregning(PrognoseType.Lav);
			this.udførBeregning(PrognoseType.Høj);
		} else {
			this.setState(previousState => {
				const newState = { ...previousState.resultFromApi };
				newState[PrognoseType.Lav] = [] as PrognoseUdbetalingsforloeb[];
				newState[PrognoseType.Høj] = [] as PrognoseUdbetalingsforloeb[];


				return {
					resultFromApi: newState
				};
			});
		}
	}

	// make sure to remove the listener
	// when the component is not mounted anymore
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	async udførBeregning(nyValgtScenarieType: PrognoseType): Promise<boolean> {
		if (this.state.resultFromApi[nyValgtScenarieType]) {
			return;
		}

		const data: any = {
			prognoseScenarie: nyValgtScenarieType, visMedlemskaberSamlet: this.props.visMedlemskaberSamlet, visForloebAlderspension: this.props.visForloebAlderspension, afrundPrognosetal: this.props.afrundPrognosetal, blockId: this.props.blockId
		};
		const response = await fetch('/api/PrognoseUdbetalingsforloebApi/HentPrognoseUdbetalingsForloeb', {
			method: 'POST',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		});

		const jsonData = await response.json();
		this.setState(previousState => {
			const newState = { ...previousState.resultFromApi };

			if (jsonData != null && jsonData.length > 0) {
				newState[nyValgtScenarieType] = jsonData as PrognoseUdbetalingsforloeb[];
			} else {
				newState[nyValgtScenarieType] = [] as PrognoseUdbetalingsforloeb[];
			}

			return {
				resultFromApi: newState
			};
		});

		return jsonData != null && jsonData.length > 0;
	}


	udfyldPrognoseData(Komponent: PrognoseKomponent) {
		const self = this;
		let overskrift = '';

		if (this.state.nyValgtScenarieType == 0) {
			overskrift = self.props.overskriftMedlemskabLav;
		}

		if (this.state.nyValgtScenarieType == 1) {
			overskrift = self.props.overskriftMedlemskabMellem;
		}

		if (this.state.nyValgtScenarieType == 2) {
			overskrift = self.props.overskriftMedlemskabHoej;
		}

		if (this.state.resultFromApi[this.state.nyValgtScenarieType]) {
			return this.state.resultFromApi[this.state.nyValgtScenarieType].map(function (medlemskab, index) {
				return (
					<div key={ self.state.nyValgtScenarieType + '_' + medlemskab.MedlemskabsNavn }>
						<div><h4>{ overskrift.replace('[MedlemskabNavn]', medlemskab.MedlemskabsNavn).replace('[PrognosetypeTekst]', medlemskab.PrognosetypeTekst) } </h4>
							<Komponent { ...medlemskab } Felter={ self.props } Index={ index } />
						</div>
					</div>
				);
			});
		}

		return null;
	}

	seMineTal = () => {

		this.setState({ visMineTal: true });
		setTimeout(function () {
			const myElement = document.getElementById('squareButton');
			const topPos = window.pageYOffset + myElement.getBoundingClientRect().top;
			window.scrollTo({
				top: topPos,
				left: 0,
				behavior: 'smooth'
			});
		}, 500);
		this.gemMedlemsAdfaerd(this.state.nyValgtScenarieType);
		this.gemInteraktion(this.state.nyValgtScenarieType);
	};

	gemMedlemsAdfaerd(prognosetype: PrognoseType) {
		MedlemsAdfaerdService.gemAdfaerd({
			betjening: this.props.baseInteraktionDialogId,
			handling: this.props.scenarieTypeListe[prognosetype].adfaerdHandling,
			xrmSagsId: null,
			vaerdier: null
		});
	}

	gemInteraktion(prognosetype: PrognoseType) {
		if (this.state.resultFromApi[this.state.nyValgtScenarieType] === null || this.state.resultFromApi[this.state.nyValgtScenarieType]?.length === 0) {
			if (this.props.visForloebAlderspension) {
				MedlemsAdfaerdService.gemInteraktion('Beregning fejlede', this.props.baseInteraktionDialogId, this.props.scenarieTypeListe[prognosetype].adfaerdHandling, 'WEB');
			} else {
				MedlemsAdfaerdService.gemInteraktion('Beregning fejlede INV', this.props.baseInteraktionDialogId, this.props.scenarieTypeListe[prognosetype].adfaerdHandling, 'WEB');
			}
		} else {
			if (this.props.visForloebAlderspension) {
				MedlemsAdfaerdService.gemInteraktion('UdbetalingsforløbetErVist', this.props.baseInteraktionDialogId, this.props.scenarieTypeListe[prognosetype].adfaerdHandling, 'WEB');
			} else {
				MedlemsAdfaerdService.gemInteraktion('UdbetalingsforløbetErVist INV', this.props.baseInteraktionDialogId, this.props.scenarieTypeListe[prognosetype].adfaerdHandling, 'WEB');
			}
		}
	}

	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};

	onPrognoseChanged(event: React.ChangeEvent<HTMLSelectElement>) {
		const newValue = parseInt(event.currentTarget.value) as PrognoseType;
		this.setState({ nyValgtScenarieType: newValue });
		this.gemMedlemsAdfaerd(newValue);
		this.gemInteraktion(newValue);
	}

	render() {
		const { width } = this.state;
		let isMobile = width <= 500;

		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			isMobile = true;

		}


		if (window.screen.width <= 500) {
			isMobile = true;
		}


		const options = this.props.scenarieTypeListe.map(function (scenarieType, index) {
			return <option value={ scenarieType.vaerdi } key={ index } >{ scenarieType.label }</option>;
		});

		const prognoserPrMedlemskabGraf = this.udfyldPrognoseData(UdbetalingsForloebGraf);
		const prognoserPrMedlemskabTabel = this.udfyldPrognoseData(UdbetalingsForloebTabel);

		let fejlTekst = null;

		if (this.state.resultFromApi[this.state.nyValgtScenarieType] ?.length === 0) {
			fejlTekst = (
				<div>
					<div className="columns medium-12 large-12">
						<div data-alert className="alert-box alert">
							<div dangerouslySetInnerHTML={ { __html: this.props.fejlTekst } } />
						</div>
					</div>
				</div>
			);
		}

		let footer = null;

		if (this.state.nyValgtScenarieType == 0) {
			footer = this.props.footerTekstTilLavPrognose;
		}

		if (this.state.nyValgtScenarieType == 1) {
			footer = this.props.footerTekstTilMellemPrognose;
		}

		if (this.state.nyValgtScenarieType == 2) {
			footer = this.props.footerTekstTilHoejPrognose;
		}

		const element = document.querySelector('html');
		const showBoxClass = this.props.erAlderspensionist || element.classList.contains('lp') ? ' medium-12 large-12' : ' medium-9 large-9';


		const indledning = (
			<div className="udbetalingsforlob">
				<div className="row top-content">
					<div className={ 'columns small-12' + showBoxClass }>
						<div className="row-header-large">
							<h2>{ this.props.overskrift }</h2>
							<div
								className="columns medium-10 large-10 subheader"
								dangerouslySetInnerHTML={ {
									__html: this.props.underOverskrift.replace('[folkepensionsalder]', this.props.folkepensionsalder)
								} } />
						</div>
					</div>
					{ !this.props.erAlderspensionist && !this.props.visÍkkeFolkepensionsBoks && (
						<div className="columns  medium-3 large-3 alder-content">
							<span>{ this.props.folkepensionsalderLabel }</span> <h2>{ this.props.folkepensionsalder }</h2>
						</div>
					) }
				</div>

				<div className="row dropdown-content">
					<h6 className="columns medium-12 large-12 center">
						{ this.props.dropdownLabel }
					</h6>

					<div className="columns medium-5 large-5 select-column">
						<select className="chosen-select" onChange={ this.onPrognoseChanged } value={ this.state.nyValgtScenarieType }>{ options }</select>
					</div>
					<div className="columns medium-7 large-7 select-column">
						&nbsp;
					</div>
				</div>

				<TabContainer selected={ isMobile ? 1 : 0 }>
					<Tab tabHeader={ this.props.faneGraf } >
						<div className="columns small-12 tab-container">
							<div className="row">
								<LoadingSpinner loadingText="Vent, mens vi regner på det..." isLoading={ !prognoserPrMedlemskabGraf } />
								<div className="row">
									<div className="columns medium-12 large-12">
										<div className="row row-header-large">
											<div className="columns small-12">

												<h1>{ this.props.overskriftGraf }</h1>
											</div>
										</div>
										<div className="row row-header-large">
											<div
												className="columns small-12 medium-12 subheader"
												dangerouslySetInnerHTML={ {
													__html: this.props.underOverskriftGraf
												} } />
											{ this.state.nyValgtScenarieType == 1 && (
												<div
													className="columns small-12 medium-12 subheader"
													dangerouslySetInnerHTML={ {
														__html: this.props.forventetPrognoseTekstGraf
													} } />
											) }
										</div>

										{ prognoserPrMedlemskabGraf }

										<div
											dangerouslySetInnerHTML={ {
												__html: footer
											} } />
									</div>
								</div>
							</div>
						</div>
					</Tab>

					<Tab tabHeader={ this.props.faneTabel }>
						<div className="columns small-12 tab-container">
							<div className="row">
								<LoadingSpinner loadingText="Vent, mens vi regner på det..." isLoading={ !prognoserPrMedlemskabTabel } />
								<div className="row">
									<div className="columns medium-12 large-12">
										<div className="row row-header-large">
											<div className="columns small-12">
												<h1>{ this.props.overskriftTabel }</h1>
											</div>
										</div>
										<div className="row row-header-large">
											<div
												className="columns medium-12 large-12 subheader"
												dangerouslySetInnerHTML={ {
													__html: this.props.underOverskriftTabel
												} } />
											{ this.state.nyValgtScenarieType == 1 && (
												<div
													className="columns medium-12 large-12 subheader"
													dangerouslySetInnerHTML={ {
														__html: this.props.forventetPrognoseTekstTabel
													} } />
											) }
										</div>
										{ prognoserPrMedlemskabTabel }
										<div
											dangerouslySetInnerHTML={ {
												__html: footer
											} } />
									</div>
								</div>
							</div>
						</div>
					</Tab>
				</TabContainer>

				{ fejlTekst != null && (
					<div className="row">
						<div className="row">
							<div className="columns medium-12 large-12">
								{ fejlTekst }
							</div>
						</div>
					</div>
				) }
			</div>
		);

		return (
			<div>
				<div className="row">
					<div>{ isMobile }</div>
					<div className="columns medium-12 medium-centered large-12 text-center">
						<button className="square-button button-type-3 tal-button" id="squareButton" onClick={ this.seMineTal } disabled={ this.state.visMineTal }>{ this.props.knapTekst }</button>
					</div>
				</div>

				{ this.state.visMineTal && indledning }

			</div>
		);
	}
}

export default UdbetalingsForloeb;