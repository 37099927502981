import React, { useEffect, useState } from 'react';

import { VideoSkyfishProps } from './videoSkyfish.definition';

export const VideoSkyfish = (props: VideoSkyfishProps) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		if (!document.getElementById('video-js')) {
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.id = 'video-js';
			script.defer = true;
			script.src = 'https://vjs.zencdn.net/7.6.6/video.js';
			document.body.appendChild(script);

			setTimeout(() => {
				setIsLoaded(true);
			}, 500);
		} else {
			setTimeout(() => {
				setIsLoaded(true);
			}, 500);
		}
	}, []);

	useEffect(() => {
		if (isPlaying) {
			const { id } = props;
			const { videojs } = window;

			videojs(id);
		}
	}, [isPlaying]);

	const handleSkyfishRegex = (url: string) => {
		const videoId = url.split('video.skyfish.com/')[1].split('/')[0];
		const videoStreamId = url.split('stream-')[1].split('&')[0];
		const videoFormat = `AppleHLS1/stream-${videoStreamId}`;

		const newUrl = `https://video.skyfish.com/${videoId}/${videoFormat}`;

		return newUrl;
	};

	const { url, id } = props;

	if (!isLoaded) {
		return null;
	}

	return (
		<div className="video-js-wrapper">
			<video
				id={ id }
				className="video-js"
				controls
				preload="auto"
				data-setup='{ "controls": true, "autoplay": true, "preload": "auto" }'
				onClick={ () => setIsPlaying(!isPlaying) }
			>
				<source
					src={ handleSkyfishRegex(url) }
					type="application/x-mpegURL"
				/>
				<p className="vjs-no-js">
					For at se denne videoen skal du aktivere JavaScript og overveje at opgradere til en webbrowser, der
					<a
						href="https://videojs.com/html5-video-support/"
						target="_blank"
						rel="noreferrer"
					>
						understøtter HTML5-video
					</a>
				</p>
			</video>
		</div>
	);
};

export default VideoSkyfish;