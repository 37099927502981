import React, { Component } from 'react';

import TekstOgMedieSliderForside from './components/sliderforside';
import TekstOgMedieSliderAfslutningsside from './components/sliderafslutningsside';
import TekstOgMedieSliderSteps from './components/sliderSteps';
import TekstOgMedieSliderOverview from './components/sliderOverview';

import { SliderSubStep } from './components/sliderSteps/sliderSteps.definition';
import { TekstOgMedieSliderProps, TekstOgMedieSliderState } from './slider.definition';
import VideoQuoteBlock from '@Components/videoQuoteBlock';
import VideoSkyfish from '@Components/videoSkyfish';

export class TekstOgMedieSlider extends Component<TekstOgMedieSliderProps, TekstOgMedieSliderState> {
	switching = false;

	lastTouchY = 0;

	breakpointForCompactView = 960;

	breakpointForMobileVideo = 640;

	breakpointForLarge = 1440;

	constructor(props: any) {
		super(props);

		this.state = {
			mainStepIndex: 0,
			subStepIndex: 0,
			showMobileVideo: false,
			visForside: true,
			visSteps: false,
			visAfslutningsside: false,
			isCompactView: window.innerWidth <= this.breakpointForCompactView,
			useMobileVideo: window.innerWidth <= this.breakpointForMobileVideo || (window.innerWidth > this.breakpointForCompactView && window.innerWidth < this.breakpointForLarge)
		};

		this.handleWheel = this.handleWheel.bind(this);
		this.handleTouchStart = this.handleTouchStart.bind(this);
		this.handleTouchMove = this.handleTouchMove.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleRefreshVideo = this.handleRefreshVideo.bind(this);

		this.onHideForside = this.onHideForside.bind(this);
		this.onShowSteps = this.onShowSteps.bind(this);
		this.updateDimensions = this.updateDimensions.bind(this);

		this.moveUp = this.moveUp.bind(this);
		this.moveDown = this.moveDown.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		window.addEventListener('keyup', this.handleKeyPress);

		if ('onorientationchange' in window) {
			window.addEventListener('orientationchange', this.updateDimensions);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
		window.removeEventListener('keyup', this.handleKeyPress);

		if ('onorientationchange' in window) {
			window.removeEventListener('orientationchange', this.updateDimensions);
		}
		this.styleBackground();
	}

	touchMovePreventer(event: Event) {
		event.preventDefault();
	}

	styleBackground() {
		if (!this.state.useMobileVideo) {
			const body = document.getElementsByTagName('BODY')[0] as any;
			body.style.background = 'linear-gradient(90deg, #edeeec 50%, #FFFFFF 50%)';
		}
	}

	updateDimensions() {
		const isCompactView = window.innerWidth <= this.breakpointForCompactView;
		const useMobileVideo = window.innerWidth <= this.breakpointForMobileVideo || (window.innerWidth > this.breakpointForCompactView && window.innerWidth < this.breakpointForLarge);

		if (isCompactView != this.state.isCompactView || useMobileVideo != this.state.useMobileVideo) {
			this.setState({
				isCompactView: isCompactView,
				useMobileVideo: useMobileVideo
			});
		}
	}

	doSlide(delta: number) {
		if (this.switching) {
			return;
		}

		this.switching = true;
		const self = this;
		setTimeout(function () {
			self.switching = false;
		}, 2000);

		if (delta === -1 && this.state.showMobileVideo && this.state.isCompactView) {
			this.setState({
				showMobileVideo: false
			});

			return;
		}

		if (delta === 1 && !this.state.showMobileVideo && this.state.isCompactView) {
			this.setState({
				showMobileVideo: true
			});

			return;
		}

		const currentElement = this.props.steps[this.state.mainStepIndex];

		if ((delta < 0 && this.state.subStepIndex > 0) || (delta > 0 && this.state.subStepIndex < currentElement.subSteps.length - 1)) {
			this.setState({
				subStepIndex: this.state.subStepIndex + delta,
				showMobileVideo: false
			});

			this.styleBackground();
		} else if ((delta < 0 && this.state.mainStepIndex > 0) || (delta > 0 && this.state.mainStepIndex < this.props.steps.length - 1)) {
			const newStep = this.props.steps[this.state.mainStepIndex + delta];
			const newSubStepIndex = delta < 0 ? newStep.subSteps.length - 1 : 0;

			this.setState({
				mainStepIndex: this.state.mainStepIndex + delta,
				subStepIndex: newSubStepIndex,
				showMobileVideo: false
			});

			this.styleBackground();
		} else if (delta < 0) {
			this.setState({ visSteps: false });
			setTimeout(function () {
				this.setState({ visForside: true });
			}.bind(this), 800);
			document.removeEventListener('touchmove', this.touchMovePreventer);
		} else {
			this.setState({ visSteps: false });
			setTimeout(function () {
				this.setState({ visAfslutningsside: true });
			}.bind(this), 800);
			document.removeEventListener('touchmove', this.touchMovePreventer);
		}
	}

	moveUp() {
		this.doSlide(-1);
	}

	moveDown() {
		this.doSlide(1);
	}

	getMediaElement(substep: SliderSubStep, refObject: React.RefObject<HTMLVideoElement>) {
		let media: JSX.Element;

		if (substep.videoUrl != '') {
			if (substep.videoUrl.includes('skyfish')) {
				media = (
					<div className="videoWrapper">
						<VideoSkyfish url={ substep.videoUrl } id={ `skyfish-TextogMedieSlider-${substep.videoUrl}` } />
					</div>
				);
			} else if (substep.videoUrl.includes('youtube')) {
				media = (
					<div className="videoWrapper">
						<VideoQuoteBlock epiVideoUrl={ substep.videoUrl } id={ `skyfish-Slider-${substep.videoUrl}` } MarketingCookiesDisabledText="" Headline="" Baggrundsfarve="" />
					</div>
				);
			} else {
				media = (
					<div className="videoWrapper">
						<video ref={ refObject } key={ substep.videoUrl + this.state.useMobileVideo } className="campaign-video" muted playsInline preload="auto">
							<source src={ (this.state.useMobileVideo) ? substep.videoUrlMobileVersion : substep.videoUrl } type="video/mp4" />
						</video >
						<i onClick={ () => this.handleRefreshVideo(refObject) } className="videoRefresh icon-refresh" />
						<div className="disclaimerText" dangerouslySetInnerHTML={ { __html: this.props.disclaimer } } />
					</div>
				);
			}
		} else {
			media = <div><img src={ substep.imageUrl } key={ substep.imageUrl } className="campaign-image" /><p className="disclaimerText" dangerouslySetInnerHTML={ { __html: this.props.disclaimer } } /></div >;
		}

		return media;
	}

	handleTouchStart(event: React.TouchEvent) {
		this.lastTouchY = event.targetTouches[0].clientY;
		event.preventDefault();
		event.stopPropagation();
	}

	handleTouchMove(event: React.TouchEvent) {
		if (this.lastTouchY < event.targetTouches[0].clientY) {
			this.doSlide(-1);
		} else {
			this.doSlide(1);
		}

		event.preventDefault();
		event.stopPropagation();
	}

	handleWheel(event: React.WheelEvent) {
		let delta = 1;

		if (event.deltaY < 0) {
			delta = -1;
		}

		this.doSlide(delta);
	}

	handleRefreshVideo(ref: any) {
		ref.current.currentTime = 0;
	}

	handleKeyPress(event: KeyboardEvent) {
		if (this.state.visSteps) {
			if ((event.key === 'ArrowUp') || (event.key === 'Up')) {
				this.moveUp();
			} else if ((event.key === 'ArrowDown') || (event.key === 'Down')) {
				this.moveDown();
			}
		}
	}

	onHideForside() {
		setTimeout(function () {
			this.setState({ visForside: false });

			const footer = document.getElementsByClassName('site-footer')[0] as HTMLHtmlElement;
			footer.style.display = 'none';
			document.body.style.overflow = 'hidden';

			this.styleBackground();
		}.bind(this), 500);
		this.setState({ visSteps: true });

		//IOS < 13 workaround to prevent site from scrolling
		document.addEventListener('touchmove', this.touchMovePreventer, { passive: false });
	}

	onShowSteps() {
		this.setState({ visSteps: true });
		this.setState({ visAfslutningsside: false });

		//IOS < 13 workaround to prevent site from scrolling
		document.addEventListener('touchmove', this.touchMovePreventer, { passive: false });
		this.styleBackground();
	}

	render() {
		const self = this;
		const videoPlayerRef = React.createRef<HTMLVideoElement>();

		const currentItem = this.props.steps[this.state.mainStepIndex].subSteps[this.state.subStepIndex];
		const media = this.getMediaElement(currentItem, videoPlayerRef);

		setTimeout(function () {
			if (videoPlayerRef.current != null && (!self.state.isCompactView || self.state.showMobileVideo)) {
				videoPlayerRef.current.play();
			}
		}, 1500);

		//Preload the video manually - Necessary for IOS devices
		setTimeout(function () {
			if (videoPlayerRef.current != null && self.state.isCompactView && !self.state.showMobileVideo && videoPlayerRef.current.readyState < 3) {
				videoPlayerRef.current.load();
			}
		}, 100);

		let forsideSlider = null;
		const { forside } = this.props;

		if (this.state.visForside) {
			forsideSlider = <TekstOgMedieSliderForside { ...forside } onHideForside={ this.onHideForside } />;
		}

		let stepOverview = null;
		let stepTitle = null;
		let sliderContent = null;

		if (!this.state.visForside && !this.state.visAfslutningsside) {
			let stepsClassName = this.state.visSteps ? 'showStep' : 'hideStep';

			if (!this.props.showStepNumbers) {
				stepsClassName += ' hide-overview';
			}

			if (this.props.showStepsTitle) {
				stepTitle = (
					<div className="scroll-flow-heading gradient--2 hide-for-medium-down">
						<span>{ this.props.title }</span>
					</div>
				);
			}

			if (this.props.showStepNumbers) {
				stepOverview = <TekstOgMedieSliderOverview mainStepIndex={ this.state.mainStepIndex } subStepIndex={ this.state.subStepIndex } steps={ this.props.steps } useCompactOffset={ this.state.useMobileVideo } />;
			}

			sliderContent = (
				<div onTouchStart={ this.handleTouchStart } onTouchMove={ this.handleTouchMove } onWheel={ this.handleWheel } >
					<div className="row base-band focus-box scroll-flow container" >
						<div className={ stepsClassName }>
							<div className="columns large-2 hide-for-large-down">&nbsp;</div>
							<div className="columns large-4 medium-12 content-wrapper">
								{ stepTitle }
								{ stepOverview }
								<TekstOgMedieSliderSteps mainStepIndex={ this.state.mainStepIndex } subStepIndex={ this.state.subStepIndex } showMobileVideo={ this.state.showMobileVideo } steps={ this.props.steps } mediaElement={ media } isCompactView={ this.state.isCompactView } />
							</div>
							{ !self.state.isCompactView && (
								<div className="columns large-6 medium-12 media-column">
									{ media }
								</div>
							) }

						</div>
						<div>
							<div className="columns large-2 hide-for-medium-down">&nbsp;</div>
							<div className="columns large-4 medium-12 content-wrapper">
								<div className="sliderButtons">
									<span className="icon-Pil-op up" onClick={ this.moveUp }>&nbsp;</span>
									<span className="icon-Pil-ned down" onClick={ this.moveDown }>&nbsp;</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

		let afslutningssideSlider = null;
		const { afslutningsside } = this.props;

		if (this.state.visAfslutningsside) {
			afslutningssideSlider = <TekstOgMedieSliderAfslutningsside { ...afslutningsside } onShowSteps={ this.onShowSteps } />;
		}

		return (
			<div>
				{ forsideSlider }
				{ sliderContent }
				{ afslutningssideSlider }
			</div>
		);
	}
}

export default TekstOgMedieSlider;