import { Component } from 'react';
import { TabProps } from './tab.definition';

export class Tab extends Component<TabProps, {}> {
	constructor(props: TabProps) {
		super(props);
	}

	render() {
		return this.props.children;
	}
}

export default Tab;