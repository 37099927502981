/* eslint-disable @typescript-eslint/ban-ts-comment */
/*
 * Project: forca
 * File Created: Friday, 22nd October 2021 2:08:50 pm
 * Author: Teodor Moquist (teodor@moquist.dk)
 */

import React, { Component } from 'react';

import Hotspots from './hotspots.component';

class HotspotsContainer extends Component<any> {
	containerProps() {
		const { currentBlock = null } = this.props;

		if (!currentBlock) {
			return this.props;
		}

		const {
			currentBlock: {
				overskrift,
				overskriftLeftAlign,
				manchet,
				fullLink,
				elementMargin
			},
			hotSpotList = []
		} = this.props;

		const boxes = [];

		if (hotSpotList.length > 0) {
			hotSpotList.map(hotspot => {
				const hotspotLink = hotspot.link;
				hotspot = hotspot.currentBlock;

				boxes.push({
					icon: hotspot.icon,
					svgIcon: hotspot.svgIcon,
					imageIcon: hotspot.imageIcon,
					title: hotspot.title,
					text: hotspot.text,
					isButton: hotspot.isButton,
					linkText: hotspot.linkText,
					linkHref: hotspotLink,
					ctaText: hotspot.phone,
					whiteBg: hotspot.useWhiteBackground,
					hideBooble: hotspot.hideIconBubble,
					isStatic: hotspot.isStatic
				});
			});
		}

		return {
			overskrift,
			overskriftLeftAlign,
			manchet,
			boxes,
			fullLink,
			elementMargin
		};
	}

	render() {
		return (
			<>
				{ /* @ts-ignore */ }
				<Hotspots
					{ ...this.containerProps() }
				/>
			</>
		);
	}
}

export default HotspotsContainer;