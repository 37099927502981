import React from 'react';

import { LinkProps } from './link.definition';

export const Link = (props: LinkProps) => {
	const { to } = props;

	// checks if the url is going away from of domain
	const isAbsolutePath = /^https?:\/\//.test(to);

	const {
		className = '',
		id,
		children,
		target = isAbsolutePath ? '_blank' : '_self',
		title,
		rel,
		isButton = false,
		type = 1,
		onClick
	} = props;

	if (isButton) {
		return (
			<a
				className={ [
					'square-button',
					`button-type-${type}`,
					className
				].join(' ') }
				id={ id }
				target={ target }
				title={ title }
				rel={ rel }
				href={ to }
				onClick={ onClick }
			>
				{ children }
			</a>
		);
	}

	return (
		<a
			className={ className }
			id={ id }
			target={ target }
			title={ title }
			rel={ rel }
			href={ to }
		>
			{ children }
		</a>
	);
};

export default Link;