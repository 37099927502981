import React, { Component } from 'react';
import { PrognoseUdbetalingsforloeb } from './UdbetalingsForloebTabel.definition';

export class UdbetalingsForloebTabel extends Component<PrognoseUdbetalingsforloeb, {}> {
	constructor(props: PrognoseUdbetalingsforloeb) {
		super(props);
	}

	render() {

		const self = this;
		let prognoserPrMedlemskab = null;
		let footerTekst = '';
		prognoserPrMedlemskab = this.props.PrognoserPrAlder.map(function (prognoser) {
			let vistAlder = ' år';
			const prognoseLinie = prognoser.Prognoser.map(function (prognose) {
				let kroneTekst = '';

				if (prognose.LoebendeUdbetaling) {
					kroneTekst = 'kr./år';
				} else {
					kroneTekst = 'kr.';
				}

				if (prognoser.Alder == 1 || prognoser.Alder == 0) {
					vistAlder = self.props.Felter.tekstTabelFoersteAar.replace('[folkepensionsalder]', prognoser.PensionsAlderFormateret).replace('[dato]', prognoser.DatoStartFormateret);
				} else {
					vistAlder = self.props.Felter.tekstTabelAndreAar.replace('[år]', prognoser.Alder).replace('[år-1]', (prognoser.Alder - 1).toString());
				}

				if (prognose.VisUdloebsTekst && prognoser.UdloebsdatoFormateret) {
					return <div className="row"><div className="columns medium-9 small-7 left-header">{ prognose.Label }. { self.props.Felter.tekstVedUdloeb } { prognoser.UdloebsdatoFormateret }</div> <div className="columns medium-2 small-5 amount text-right"><span className="ydelse">{ prognose.YdelseFormateret }</span><span className="kr">{ kroneTekst }</span></div></div>;
				} else {
					return <div className="row"><div className="columns medium-9 small-7 left-header">{ prognose.Label }</div> <div className="columns medium-2 small-5 amount text-right"><span className="ydelse">{ prognose.YdelseFormateret }</span><span className="kr">{ kroneTekst }</span></div></div>;
				}
			});

			if (prognoseLinie.every(x => x == null)) {
				return null;
			}

			let rowIalt = null;

			if (prognoseLinie.length > 1) {
				rowIalt = <div className="row ialt"><div className="columns medium-9 small-7 left-header">I alt</div> <div className="columns medium-2 small-5 amount text-right"><span className="ydelse">{ prognoser.SumAfPrognoserFormateret }</span><span className="kr">kr./år</span></div></div>;
			}

			return (
				<div key={ vistAlder }>
					<div className="expandable-list">
						<li>
							<div className="expandable-list__header">
								<div className="columns medium-10 small-7"> { vistAlder }</div> <div className="columns medium-2 small-5 amount text-right"><span className="ydelse"> { prognoser.SumAfPrognoserFormateret }</span> <span className="kr">kr.</span></div>
							</div>

							<div className="expandable-list__content">
								{ prognoseLinie }
								{ rowIalt }
							</div>
						</li>
					</div>
				</div>
			);
		});

		if (self.props.VisFooterTekst) {
			footerTekst = self.props.FooterTekst;
		}

		return (
			<div className="base-band columns medium-12 large-12">
				<div className="row">
					<div className="columns medium-10 small-10 udbetalingsForlobHeader" /> <div className="columns medium-2 small-2 amountHeader text-right" />
				</div>
				<div className="row">
					{ prognoserPrMedlemskab }
				</div>
				<div className="row">
					<div className="columns medium-12 small-12 ">
						{ footerTekst }
					</div>
				</div>

			</div>
		);
	}
}

export default UdbetalingsForloebTabel;