import React, { Component } from 'react';

import { FeltTypeComboBoxProps } from './felttypecombobox.definition';

export class FeltTypeComboBox extends Component<FeltTypeComboBoxProps, {}> {
	constructor(props: any) {
		super(props);

		this.onValueChanged = this.onValueChanged.bind(this);
	}

	onValueChanged(event:any) {
		this.props.onValueChanged(event.target.value);
	}

	render() {
		const items = this.props.muligeVaerdier.map((item) => {
			return (<option key={ item } value={ item }>{ item }</option>);
		});
		const feltHtml = (
			<select value={ this.props.valgtVaerdi } onChange={ this.onValueChanged }>
				{ items }
			</select>
		);

		return feltHtml;
	}
}

export default FeltTypeComboBox;