import React from 'react';

import { PengesaekProps } from './PengeSaek.definition';

export const pengeSaek = (props: PengesaekProps) => {
	const l = props.left;
	const t = props.top;
	const b = props.bottom;
	const c = props.color;
	const { kunde } = props;
	const position = props.positionIsAbsolute ? 'absolute' as const : 'unset' as const;


	const styles = {
		pengesaekStyles: {
			position: position,
			left: l + 'px',
			//top: t-170 + 'px',
			top: t !== null ? t + 'px' : 'unset',
			bottom: b !== null ? b + 'px' : 'unset',
			width: '70px'
		},
		spanIcon: {
			position: 'absolute' as const,
			right: '45px',
			top: '30px'

		},
		spanIconLp: {
			position: 'absolute' as const,
			left: '10px',
			top: '-13px'

		},
		svgIcon: {
			border: '2px red solid',
			fill: c
		},
		spanPengebox: {

		},
		spanPengeboxLp: {

		},
		svgPengeBox: {
			fill: c
		},
		svgPengeBoxLp: {
			fill: c
		}
	};

	if (isMsie11()) {
		styles.pengesaekStyles.top = t !== null ? t + 'px' : 'unset';
		styles.pengesaekStyles.bottom = '25px';
	}


	function isMsie11() {
		const retVar = false;
		const ua = window.navigator.userAgent;
		const msie = ua.indexOf('MSIE ');

		if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
			//alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
			return true;
		}


		return retVar;
	}


	const getKundePengeSaek = () => {
		if (kunde === 'LP') {
			return (
				<div>
					<span style={ styles.spanIconLp }>
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="21" height="21">
							<path fill="#003d52" stroke="#fff" strokeLinejoin="miter" strokeLinecap="butt" strokeMiterlimit="4" strokeWidth="46.5455" d="M512 23.273c269.917 0 488.727 218.811 488.727 488.727s-218.811 488.727-488.727 488.727c-269.917 0-488.727-218.811-488.727-488.727v0c0-269.917 218.811-488.727 488.727-488.727v0z" />
							<path fill="#fff" d="M469.644 373.76v-82.851h89.367v82.851zM469.644 757.295v-337.92h89.367v337.92z" />
						</svg>
					</span>
					<span style={ styles.spanPengeboxLp }>
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1100 1100" className="{styles.pengeBoxContainer}">
							<g fill="{props.color}" style={ styles.svgPengeBoxLp }>

								<path fill="{props.color}" d="M167.52 4h728.96c90.31 0 163.52 73.21 163.52 163.52v688.96c0 90.31-73.21 163.52-163.52 163.52h-728.96c-90.31 0-163.52-73.21-163.52-163.52v-688.96c0-90.31 73.21-163.52 163.52-163.52z" />
								<path fill="{props.color}" d="M896.48 8c87.991 0.272 159.248 71.529 159.52 159.494v688.986c-0.272 87.991-71.529 159.248-159.494 159.52h-728.986c-87.991-0.272-159.248-71.529-159.52-159.494v-688.986c0.272-87.991 71.529-159.248 159.494-159.52h728.986zM896.48 0h-728.96c-0.024 0-0.052 0-0.080 0-92.475 0-167.44 74.965-167.44 167.44 0 0.028 0 0.056 0 0.084v-0.004 688.96c0 0.024 0 0.052 0 0.080 0 92.475 74.965 167.44 167.44 167.44 0.028 0 0.056 0 0.084 0h728.956c0.024 0 0.052 0 0.080 0 92.475 0 167.44-74.965 167.44-167.44 0-0.028 0-0.056 0-0.084v0.004-688.96c0-0.024 0-0.052 0-0.080 0-92.475-74.965-167.44-167.44-167.44-0.028 0-0.056 0-0.084 0h0.004z" />
								<path fill="none" stroke="#fff" strokeLinejoin="miter" strokeLinecap="butt" strokeMiterlimit="10" strokeWidth="56" d="M533.6 304v424" />
								<path fill="none" stroke="#fff" strokeLinejoin="miter" strokeLinecap="butt" strokeMiterlimit="10" strokeWidth="56" d="M316 510.4h424" />
							</g>
						</svg>
					</span>
				</div>
			);
		} else {
			return (
				<div>
					<span style={ styles.spanIcon }>
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="21" height="21">
							<path fill="#003d52" stroke="#fff" strokeLinejoin="miter" strokeLinecap="butt" strokeMiterlimit="4" strokeWidth="46.5455" d="M512 23.273c269.917 0 488.727 218.811 488.727 488.727s-218.811 488.727-488.727 488.727c-269.917 0-488.727-218.811-488.727-488.727v0c0-269.917 218.811-488.727 488.727-488.727v0z" />
							<path fill="#fff" d="M469.644 373.76v-82.851h89.367v82.851zM469.644 757.295v-337.92h89.367v337.92z" />
						</svg>
					</span>
					<span style={ styles.spanPengebox }>
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 746 1024" className="{styles.pengeBoxContainer}">
							<g fill="{props.color}" style={ styles.svgPengeBox }>
								<path className="{styles.svgContainer}" d="M526.205 83.070c0-9.484-1.495-18.968-2.991-27.774-47.849 13.548-91.96 12.194-132.333-4.065-45.606-18.29-98.689-17.613-159.248 1.355-2.243 9.484-3.738 19.645-3.738 29.807 0 33.871 23.177 83.323 70.279 147.678-182.425 220.84-272.89 382.744-272.89 485.035 0 174.098 155.51 270.969 347.654 270.969s347.654-96.871 347.654-270.969c0-101.613-89.717-261.485-268.404-478.938 49.344-67.065 74.017-117.872 74.017-153.098z" />
							</g>
						</svg>
					</span>
				</div>
			);
		}

	};

	const showTooltipSum = () => {
		//if (document.getElementsByClassName('hideSumToolTip').length > 1) {
		//    document.getElementsByClassName('hideSumToolTip')[0].remove();
		//}
		//if (document.getElementsByClassName('pengesaek').length > 1) {
		//    document.getElementsByClassName('pengesaek')[1].remove();
		//}

		return (
			<div id="chartjs-tooltip-sum" className={ props.class } style={ { fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif', fontSize: '12px', fontStyle: 'normal', pointerEvents: 'none' } }>
				<div id="tooltip-title"><div className="title">{ props.folkepensionsAlder }</div>
                        [object HTMLTableElement]
				</div>
				<table id="tooltip-table">
					<tbody>
						{ props.prognoser.map((p, index) => (
							<tr key={ index }>
								<td><span className="chartjs-tooltip-key" style={ { backgroundColor: '#CF1E61', borderColor: 'rgba(220,220,220,0)', borderWidth: '2px' } } />{ p.Prognoser[0].Label }</td>
								<td><span className="chartjs-tooltip-key" style={ { backgroundColor: '#CF1E61', borderColor: 'rgba(220,220,220,0)', borderWidth: '2px' } } />{ p.Prognoser[0].YdelseFormateret } kr.</td>
							</tr>
						)) }

					</tbody>
				</table>
			</div>
		);
	};


	return (

		<div className="pengesaek" style={ styles.pengesaekStyles } onMouseOver={ props.onEnter } onMouseOut={ props.onOut }>

			{ showTooltipSum() }


			{ getKundePengeSaek() }


		</div>
	);

};

export default pengeSaek;