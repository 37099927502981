export interface MedlemsAdfaerdBase {
    betjening: string;
    handling: string;
    xrmSagsId: string;
    vaerdier: Datapunkter[];
}

export interface Datapunkter {
    feltnavn: string;
    vaerdi: string;
}

export function gemAdfaerd(adfaerd: MedlemsAdfaerdBase) {
	fetch('/api/MedlemsAdfaerdApi/GemAdfaerd', {
		method: 'POST',
		cache: 'no-cache',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(adfaerd)
	});
}

export function gemInteraktion(handlingId: string, dialogId: string, handlingVaerdi: string, kanalId: string) {
	if (!handlingId || !dialogId) {
		return;
	}
	let handlingVaerdiData = '';

	if (handlingVaerdi !== undefined) {
		handlingVaerdiData = handlingVaerdi;
	}

	let kanalIdData = '';

	if (kanalId !== undefined) {
		kanalIdData = kanalId;
	}

	const jsonData = JSON.stringify({
		HandlingId: handlingId,
		DialogId: dialogId,
		HandlingVaerdi: handlingVaerdiData,
		KanalId: kanalIdData
	});

	fetch('/api/DialogApi/GemInteraktion', {
		method: 'POST',
		cache: 'no-cache',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json'
		},
		body: jsonData
	});
}