import React, { Component } from 'react';

import { TekstOgMedieSliderForsideProps, TekstOgMedieSliderForsideState } from './sliderforside.definition';

export class TekstOgMedieSliderForside extends Component<TekstOgMedieSliderForsideProps, TekstOgMedieSliderForsideState> {
	constructor(props: any) {
		super(props);
		this.state = {
			toggleVisible: false
		};
	}

	componentDidMount() {
		const footer = document.getElementsByClassName('site-footer')[0] as HTMLHtmlElement;
		footer.style.display = 'block';
		document.body.style.overflow = 'auto';

		const body = document.getElementsByTagName('BODY')[0] as HTMLHtmlElement;
		body.style.background = '';
	}

	onClick = () => {
		this.setState({ toggleVisible: true });
		this.props.onHideForside(true);
		window.scrollTo(0, 0);
	};

	render() {

		return (
			<div className="row step-overview-wrapper">
				<div className={ this.state.toggleVisible ? 'columns sliderForside text-center toggleVisible' : 'columns sliderForside text-center' }>
					<h2>{ this.props.forsideOverskrift }</h2>
					<h1>{ this.props.forsideHeader }</h1>
					<p dangerouslySetInnerHTML={ { __html: this.props.forsideTekst } } />

					<button type="button" onClick={ this.onClick } className="square-button button-type-14">{ this.props.forsideKnapTekst }</button>
					<img src={ this.props.forsideBilledeVenstreSideUrl } className="sliderForsideLeftImage" />
					<img src={ this.props.forsideBilledeHoejreSideUrl } className="sliderForsideRightImage" />
				</div>
			</div>
		);
	}
}

export default TekstOgMedieSliderForside;