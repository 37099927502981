import React, { Component } from 'react';

import { TekstOgMedieSliderAfslutningssideProps } from './sliderafslutningsside.definition';

export class TekstOgMedieSliderAfslutningsside extends Component<TekstOgMedieSliderAfslutningssideProps, {}> {
	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		const footer = document.getElementsByClassName('site-footer')[0] as HTMLHtmlElement;
		footer.style.display = 'block';
		document.body.style.overflow = 'auto';

		const body = document.getElementsByTagName('BODY')[0] as HTMLHtmlElement;
		body.style.background = '';
	}

	showSteps = () => {
		this.setState({
			visSteps: true
		});
		this.props.onShowSteps(true);

		const footer = document.getElementsByClassName('site-footer')[0] as HTMLHtmlElement;
		footer.style.display = 'none';
		document.body.style.overflow = 'hidden';

		window.scrollTo(0, 0);
	};

	render() {
		return (
			<div className="row">
				<div className="columns sliderAfslutningsside text-center">
					<div className="backbutton-column">
						<button type="button" onClick={ this.showSteps } className="back-button" title="Tilbage">
							Tilbage
						</button>
					</div>
					<h2>{ this.props.afslutningssideOverskrift }</h2>
					<h1>{ this.props.afslutningssideHeader }</h1>
					<p dangerouslySetInnerHTML={ { __html: this.props.afslutningssideTekst } } />

					<div>
						{ this.props.afslutningssideKnapper && this.props.afslutningssideKnapper.map(function (knap) {
							return (
								<a key={ knap.link } href={ knap.link } className="square-button button-type-14 button">
									{ knap.title }
								</a>
							);
						}) }
					</div>

					<img src={ this.props.afslutningssideBilledeVenstreSideUrl } className="sliderForsideLeftImage" />
					<img src={ this.props.afslutningssideBilledeHoejreSideUrl } className="sliderForsideRightImage" />
				</div>
				<div className="columns text-center additionalText">
					<p dangerouslySetInnerHTML={ { __html: this.props.afslutningssideAdditionalTekst } } />
				</div>
			</div>
		);
	}
}

export default TekstOgMedieSliderAfslutningsside;