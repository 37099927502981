import React, { Component } from 'react';

import ChangeReportEntry from '../changeReportEntry';
import { ChangeReportGroupingProps } from './changeReportGrouping.definition';

export class ChangeReportGrouping extends Component<ChangeReportGroupingProps, {}> {
	constructor(props: ChangeReportGroupingProps) {
		super(props);
		this.openComparison = this.openComparison.bind(this);
	}

	openComparison() {
		this.props.onOpenComparison(this.props);
	}

	render() {

		const entries = this.props.entries.map((entry => <ChangeReportEntry actionType={ entry.actionType } changeDate={ entry.changeDate } changedBy={ entry.changedBy } contentLink={ entry.contentLink } key={ entry.changeDate + entry.actionType } />));

		return (
			<div className="top-margin-1rem">
				<div className="row version-header">
					<div className="small-11 columns">
						<strong>{ this.props.contentName }</strong>
					</div>
					<div className="small-1 columns">
						<span onClick={ this.openComparison } className="icon-expanded-black right" />
					</div>
				</div>
				{ entries }
			</div>
		);
	}
}

export default ChangeReportGrouping;